import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Layout
const Layout = lazy(() => import('../layouts/Layout.jsx'));
const LayoutHeaderless = lazy(() => import('../layouts/LayoutHeaderless.jsx'));

// PLG
const SlackOnboarding = lazy(() => import('../views/PLG/slackOnboarding'));

// DEVELOP
const RecruitmentHome = lazy(() => import('../views/recruitment/recruitmentHome'));
const RecruitmentInterviews = lazy(() => import('../views/recruitment/recruitmentInterviews'));
const RecruitmentCandidates = lazy(() => import('../views/recruitment/recruitmentCandidates'));
const RecruitmentJobs = lazy(() => import('../views/recruitment/recruitmentJobs'));
const RecruitmentReports = lazy(() => import('../views/recruitment/recruitmentReports'));

const OnboardingHR = lazy(() => import('../views/onboarding/onboardingHR'));
const OnboardingPlan = lazy(() => import('../views/onboarding/onboardingPlan'));
const OnboardingTemplate = lazy(() => import('../views/onboarding/onboardingTemplate'));
const OnboardingReporting = lazy(() => import('../views/onboarding/onboardingReporting'));

const CareerHome = lazy(() => import('../views/career/careerHome'));
const CareerTeam = lazy(() => import('../views/career/careerTeam'));
const CareerHR = lazy(() => import('../views/career/careerHR'));
const CareerGuidelineCreate = lazy(() => import('../views/career/careerGuidelineCreate'));

// PERFORM
const PerformanceHome = lazy(() => import('../views/performance/performanceHome'));
const PerformanceStrengths = lazy(() => import('../views/performance/performanceStrengths'));
const PerformanceGrowth = lazy(() => import('../views/performance/performanceGrowth'));
const PerformanceReview = lazy(() => import('../views/performance/performanceReview'));
const PerformanceDiscuss = lazy(() => import('../views/performance/performanceDiscuss'));
const PerformanceFeedback = lazy(() => import('../views/performance/performanceFeedback'));

const PerformanceCampaignResult = lazy(() => import('../views/performance/performanceCampaignResult'));

const PerformanceTeam = lazy(() => import('../views/performance/performanceTeam'));
const PerformanceTeamFeedback = lazy(() => import('../views/performance/performanceTeamFeedback'));

const PerformanceHR = lazy(() => import('../views/performance/performanceHR'));
const PerformanceCampaigns = lazy(() => import('../views/performance/performanceCampaigns'));
const PerformanceCampaign = lazy(() => import('../views/performance/performanceCampaign'));
const PerformanceAdmin = lazy(() => import('../views/performance/performanceAdmin'));

const CalibrationHome = lazy(() => import('../views/calibration/calibrationHome'));
const CalibrationTalentMap = lazy(() => import('../views/calibration/calibrationTalentMap'));
const CalibrationHR = lazy(() => import('../views/calibration/calibrationHR'));

// ENGAGE
const PulseHome = lazy(() => import('../views/pulse/pulseHome'));
const PulseQuestionProfile = lazy(() => import('../views/pulse/pulseQuestionProfile'));
const PulseDriverProfile = lazy(() => import('../views/pulse/pulseDriverProfile'));
const PulseCreateCampaign = lazy(() => import('../views/pulse/pulseCreateCampaign'));
const PulseCampaigns = lazy(() => import('../views/pulse/pulseCampaigns'));
const PulseCampaign = lazy(() => import('../views/pulse/pulseCampaign'));
const PulseReporting = lazy(() => import('../views/pulse/pulseReporting'));
const PulseSurvey = lazy(() => import('../views/survey/pulseSurvey'));
const PulseDaily = lazy(() => import('../views/survey/pulseDaily'));

const RecognitionHome = lazy(() => import('../views/recognition/recognitionHome'));
const RecognitionCreate = lazy(() => import('../views/recognition/recognitionCreate'));
const RecognitionLeaderboards = lazy(() => import('../views/recognition/recognitionLeaderboards'));
const RecognitionRewards = lazy(() => import('../views/recognition/recognitionRewards'));

const OneOnOne = lazy(() => import('../views/oneOnOne/oneOnOne'));
const OneOnOneMeeting = lazy(() => import('../views/oneOnOne/oneOnOneMeeting'));
const OneOnOnePersonal = lazy(() => import('../views/oneOnOne/oneOnOnePersonal'));
const OneOnOneHR = lazy(() => import('../views/oneOnOne/oneOnOneHR'));

// ESSENTIALS
const DaysOffHome = lazy(() => import('../views/daysOff/daysoffHome'));
const DaysOffRequest = lazy(() => import('../views/daysOff/daysoffRequest'));
const DaysOffTeam = lazy(() => import('../views/daysOff/daysoffTeam'));
const DaysOffAward = lazy(() => import('../views/daysOff/daysOffAward'));
const DaysOffTeamCalendar = lazy(() => import('../views/daysOff/daysoffTeamCalendar.js'));
const DaysOffHR = lazy(() => import('../views/daysOff/daysoffHR'));

const PunchHome = lazy(() => import('../views/punch/punchHome'));
const PunchTeam = lazy(() => import('../views/punch/punchTeam'));
const PunchHR = lazy(() => import('../views/punch/punchHR'));
const PunchCreateSchedule = lazy(() => import('../views/punch/punchCreateSchedule'));

const Profile = lazy(() => import('../views/profile/profile'));
const ProfileJob = lazy(() => import('../views/profile/profileJob'));
const ProfileComp = lazy(() => import('../views/profile/profileComp'));

const SettingsHome = lazy(() => import('../views/settings/settingsHome'));
const SettingsCompany = lazy(() => import('../views/settings/settingsCompany'));

const HRInbox = lazy(() => import('../views/HRinbox/HRinbox'));
const HRDashboard = lazy(() => import('../views/HRinbox/HRDashboard'));

const Organigram = lazy(() => import('../views/organigram/organigramHome'));
const OrganigramList = lazy(() => import('../views/organigram/organigramEmployeeList'));
const OrganigramEmployeeCreate = lazy(() => import('../views/organigram/organigramEmployeeCreate'));
const OrganigramReOrganize = lazy(() => import('../views/organigram/organigramReorganize'));

// SuperAdmin
const SuperAdmin = lazy(() => import('../views/admin/superAdminHome'));
const CCOaaS = lazy(() => import('../views/admin/CCOaaS'));
const StyleGuide = lazy(() => import('../views/admin/styleguide'));
const CompanyAdmin = lazy(() => import('../views/admin/companyAdmin.js'));
const CompanyCreate = lazy(() => import('../views/admin/companyCreate.js'));
const CompanyDetail = lazy(() => import('../views/admin/companyDetail.js'));
const AIExperiments = lazy(() => import('../views/admin/aiExperiments.js'));

// Move outside
const ResetPassword = lazy(() => import('../authentication/resetPassword.js'));
const RecognitionPublicSingleRecognition = lazy(() => import('../views/recognition/recognitionPublicSingleRecognition.js'));

const Home = lazy(() => import('../Home'));
const Loader = lazy(() => import('../common/support/loader'));

const NothingThere = lazy(() => import('../common/nothingThere'));

// ---------------------------------------------------------------------------------------------------
function Routing () {
    const location = useLocation();
    const background = location.state && location.state.background;

    return (
    <React.Fragment>
        {/* Pass location to Routes so that Page remains background when ModalVersion is opened */}
        <Suspense fallback={<Loader />}>
            <Routes location={background || location} >
                {/* Product Led Growth screens - UNPROTECTED */}
                <Route path="/slack/redirect" element={<SlackOnboarding />} />

                {/* Platform Layout */}
                <Route element={<Layout />} > 
                    {/* Home */}
                    <Route path="/" element={<Home />} />

                    {/* ENGAGE */}
                    <Route path="/recognition/" element={<RecognitionHome/>}/>
                    <Route path="/recognition/leaderboard/" element={<RecognitionLeaderboards/>}/>
                    <Route path="/recognition/create" element={<RecognitionCreate/>}/> {/* Modal - fixing issue when missing background 8/1/2025 */}
                    <Route path="/recognition/rewards/" element={<RecognitionRewards/>}/>

                    <Route path="/oneonone/" element={<OneOnOne/>}/>
                    <Route path="/oneonone/team" element={<OneOnOne/>}/>
                    <Route path="/oneonone/self" element={<OneOnOne/>}/>
                    <Route path="/oneonone/meeting/:encryptedParams" element={<OneOnOneMeeting/>}/>
                    <Route path="/oneonone/space/" element={<OneOnOnePersonal/>}/>
                    <Route path="/oneonone/space/:encryptedParams" element={<OneOnOnePersonal/>}/>
                    <Route path="/oneonone/hr" element={<OneOnOneHR/>}/>
                        
                    <Route path="/pulse/" element={<PulseHome/>}/>
                    <Route path="/pulse/campaigns" element={<PulseCampaigns/>}/>
                    <Route path="/pulse/campaigns/:campaignID" element={<PulseCampaign/>}/>
                    <Route path="/pulse/reporting" element={<PulseReporting/>}/>
                    <Route path="/pulse/question/:questionID" element={<PulseQuestionProfile/>}/>
                    <Route path="/pulse/driver/:encryptedParams" element={<PulseDriverProfile/>}/>

                    {/* DEVELOP */}
                    <Route path="/recruitment/" element={<RecruitmentHome />}/>
                    <Route path="/recruitment/interviews" element={<RecruitmentInterviews />}/>
                    <Route path="/recruitment/candidates" element={<RecruitmentCandidates />}/>
                    <Route path="/recruitment/jobs" element={<RecruitmentJobs />}/>
                    <Route path="/recruitment/reports" element={<RecruitmentReports />}/>

                    <Route path="/onboarding/" element={<OnboardingPlan/>}/>
                    <Route path="/onboarding/hr" element={<OnboardingHR/>}/>
                    <Route path="/onboarding/plan/:encryptedParams" element={<OnboardingPlan/>}/>
                    <Route path="/onboarding/plan/" element={<OnboardingPlan/>}/>
                    <Route path="/onboarding/template" element={<OnboardingTemplate/>}/>
                    <Route path="/onboarding/template/:encryptedParams" element={<OnboardingTemplate/>}/>
                    <Route path="/onboarding/reporting" element={<OnboardingReporting/>}/>

                    <Route path="/career/" element={<CareerHome/>}/>
                    <Route path="/career/team" element={<CareerTeam/>}/>
                    <Route path="/career/team/:encryptedParams" element={<CareerHome/>}/>
                    <Route path="/career/hr" element={<CareerHR/>}/>
                    <Route path="/career/hr/guideline/create" element={<CareerGuidelineCreate/>}/>
                    <Route path="/career/hr/guideline/edit/:encryptedParams" element={<CareerGuidelineCreate/>}/>
                    <Route path="/career/guideline/:encryptedParams" element={<CareerGuidelineCreate/>}/>

                    {/* PERFORM */}
                    <Route path="/calibration/" element={<CalibrationHome />} />
                    <Route path="/calibration/talentMap/" element={<CalibrationTalentMap />} />
                    <Route path="/calibration/hr/" element={<CalibrationHR />} />

                    <Route path="/performance/" element={<PerformanceHome/>}/>
                    <Route path="/performance/self" element={<PerformanceHome/>}/>
                    <Route path="/performance/self/:notificationID" element={<PerformanceHome/>}/>
                    <Route path="/performance/self/strengths" element={<PerformanceStrengths/>}/>
                    <Route path="/performance/self/growth" element={<PerformanceGrowth/>}/>
                    <Route path="/performance/self/review" element={<PerformanceReview/>}/>
                    <Route path="/performance/self/discuss" element={<PerformanceDiscuss/>}/>
                    <Route path="/performance/self/campaign/:companyIDparam/:id/:employeeIDparam" element={<PerformanceCampaignResult/>}/>
                    
                    <Route path="/performance/feedback/:peerEmployeeID/:peerCompanyID/:peerCampaignID/:selfEmail/:notificationID" element={<PerformanceFeedback/>}/>

                    <Route path="/performance/team" element={<PerformanceTeam/>}/>
                    <Route path="/performance/team/employee/:encryptedParams" element={<PerformanceHome/>}/>
                    <Route path="/performance/team/employee/strengths/:encryptedParams" element={<PerformanceStrengths/>}/>
                    <Route path="/performance/team/employee/growth/:encryptedParams" element={<PerformanceGrowth/>}/>
                    <Route path="/performance/team/employee/review/:encryptedParams" element={<PerformanceReview/>}/>
                    <Route path="/performance/team/employee/discuss/:encryptedParams" element={<PerformanceDiscuss/>}/>
                    <Route path="/performance/team/employee/campaign/:companyIDparam/:id/:employeeIDparam" element={<PerformanceCampaignResult/>}/>

                    <Route path="/performance/campaigns" element={<PerformanceCampaigns/>}/>
                    <Route path="/performance/campaigns/create" element={<PerformanceCampaign/>}/>
                    <Route path="/performance/campaigns/edit/:encryptedParams" element={<PerformanceCampaign/>}/>
                    <Route path="/performance/admin" element={<PerformanceAdmin/>}/>
                    <Route path="/performance/hr/" element={<PerformanceHR/>}/>

                    {/* ESSENTIALS */}
                    <Route path="/inbox/" element={<HRInbox/>}/>
                    <Route path="/inbox/hr" element={<HRDashboard/>}/>

                    <Route path="/timeoff/" element={<DaysOffHome/>}/>
                    <Route path="/timeoff/team/" element={<DaysOffTeam/>}/>
                    <Route path="/timeoff/team/award/:encryptedParams" element={<DaysOffAward/>}/>
                    <Route path="/timeoff/team/:encryptedParams" element={<DaysOffHome/>}/>
                    <Route path="/timeoff/team/detailed/" element={<DaysOffTeamCalendar/>}/>
                    <Route path="/timeoff/hr/" element={<DaysOffHR/>}/>

                    <Route path="/punch/" element={<PunchHome/>}/>
                    <Route path="/punch/team/" element={<PunchTeam/>}/>
                    <Route path="/punch/team/:encryptedParams" element={<PunchHome/>}/>
                    <Route path="/punch/schedule/create" element={<PunchCreateSchedule/>}/>
                    <Route path="/punch/schedule/edit/:encryptedParams" element={<PunchCreateSchedule/>}/>
                    <Route path="/punch/schedule/view/:encryptedParams" element={<PunchCreateSchedule/>}/>
                    <Route path="/punch/hr/" element={<PunchHR/>}/>

                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/profile/:encryptedParams" element={<Profile/>}/>
                    <Route path="/profile/job" element={<ProfileJob/>}/>
                    <Route path="/profile/comp" element={<ProfileComp/>}/>

                    <Route path="/organigram" element={<Organigram/>}/>
                    <Route path="/organigram/employees" element={<OrganigramList/>}/>
                    <Route path="/organigram/employees/create" element={<OrganigramEmployeeCreate/>}/>
                    <Route path="/organigram/employees/edit/:encryptedParams" element={<OrganigramEmployeeCreate/>}/>
                    <Route path="/organigram/employees/reorganize" element={<OrganigramReOrganize/>}/>

                    <Route path="/settings/" element={<SettingsHome/>}/>
                    <Route path="/settings/company" element={<SettingsCompany />} />

                    <Route path="/admin/" element={<SuperAdmin/>}/>
                    <Route path="/admin/ccoaas/" element={<CCOaaS/>}/>
                    <Route path="/admin/style-guide/" element={<StyleGuide/>}/>
                    <Route path="/admin/company/" element={<CompanyAdmin/>}/>
                    <Route path="/admin/company/:id" element={<CompanyDetail/>}/>
                    <Route path="/admin/company/create" element={<CompanyCreate/>}/>
                    <Route path="/admin/ai-experiments/" element={<AIExperiments/>}/>

                </Route>
                {/* No module header Layout */}
                <Route element={<LayoutHeaderless />} >
                    {/* Externally accessible pages - no auth required  */}
                    <Route path="/pulseSurvey" element={<PulseSurvey/>}/>
                    <Route path="/pulseSurvey/" element={<PulseSurvey/>}/>
                    <Route path="/public/recognition/:id" element={<RecognitionPublicSingleRecognition/>}/>
                    {/* <Route path="/pulseDaily/:encryptedParams" element={<PulseDaily/>} /> */}
                    <Route path="/pulseDaily/" element={<PulseDaily/>} />

                    {/* Not found */}
                    <Route path="*" element={<NothingThere/>}/>
                </ Route>
            </Routes>

            {/* Open Modal if state.background is defined */}
            {background && (
                <Routes>
                    {/* ENGAGE */}
                    {/* Recognition */}
                    <Route path="/recognition/create" element={<RecognitionCreate/>}/>
                    {/* Pulse */}
                    <Route path="/pulse/campaigns/create" element={<PulseCreateCampaign/>}/>
                    <Route path="/pulse/campaigns/edit/:campaignID" element={<PulseCreateCampaign/>}/>

                    {/* ESSENTIALS */}
                    {/* Organigram */}
                    <Route path="/organigram/employees/create" element={<OrganigramEmployeeCreate/>}/>
                    <Route path="/organigram/employees/edit/:encryptedParams" element={<OrganigramEmployeeCreate/>}/>
                    {/* DaysOff */}
                    <Route path="/timeoff/request/" element={<DaysOffRequest/>}/>
                    <Route path="/timeoff/request/:encryptedParams" element={<DaysOffRequest/>}/>
                    <Route path="/timeoff/team/award/:encryptedParams" element={<DaysOffAward/>}/>
                    {/* Punch */}
                    <Route path="/punch/schedule/create" element={<PunchCreateSchedule/>}/>
                    <Route path="/punch/schedule/edit/:encryptedParams" element={<PunchCreateSchedule/>}/>
                    <Route path="/punch/schedule/view/:encryptedParams" element={<PunchCreateSchedule/>}/>

                </Routes>
            )}
        </Suspense>    
    </React.Fragment>
    );

}

export default Routing;

  

  