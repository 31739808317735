// ------------------------------------------------------------------------------------------
// Environment information
// ------------------------------------------------------------------------------------------
const devEnvironment = (process.env.REACT_APP_NODE_ENV === undefined || process.env.REACT_APP_NODE_ENV === "development" ?
 true : false);
 const stagingEnvironment = process.env.REACT_APP_NODE_ENV === "staging" ? true : false;

// ------------------------------------------------------------------------------------------
// URIs
// ------------------------------------------------------------------------------------------
const backendProdURL = "https://backend.kincode.app"
const backendStagingURL = "https://dev-backend.kincode.app";
const backendDevURL = "http://localhost:3001"

const backendURL = devEnvironment ? backendDevURL : (stagingEnvironment ? backendStagingURL : backendProdURL);

// ------------------------------------------------------------------------------------------
// Crypto
// ------------------------------------------------------------------------------------------
let privateKey = process.env.REACT_APP_PRIVATE_KEY;

// ------------------------------------------------------------------------------------------
// SecurityLevel
// ------------------------------------------------------------------------------------------
let securityLevelIC = 1;
let securityLevelInterviewer = 2;
let securityLevelManager = 3;
let securityLevelHR = 4;
let securityLevelExec = 90;
let securityLevelAdmin = 99;
let securityLevelSuperAdmin = 99999;

// ------------------------------------------------------------------------------------------
// API endpoints
// ------------------------------------------------------------------------------------------
// Logging
let platformLogErrorAPI = backendURL + "/api/platform/post/logError/";
// Authentication
let loginAPI = backendURL +  "/api/authentication/post/login/";
let authenticate = backendURL +  "/api/authentication/post/authenticate/";
let getKincodeTokenAPI = backendURL + "/api/authentication/post/authenticate/getKincodeToken"; 
let updatePasswordAPI = backendURL + "/api/authentication/post/authenticate/updatepwd";
let resetPasswordAPI = backendURL +  "/api/authentication/post/authenticate/resetpwd";
let logoutAPI = backendURL + "/api/authentication/post/logout/";

// Admin
let platformAdminGetUserActivityAPI = backendURL + "/api/platform/admin/get/userActivity/";
let platformAdminGetAllCompaniesAPI = backendURL + "/api/platform/admin/get/allCompanies/";
let platformAdminPostNewCompanyAPI = backendURL + "/api/platform/admin/post/newCompany/";
let platformAdminGetEmployeesOfCompanyAPI = backendURL + "/api/platform/admin/get/employeesOfCompany/";

// AI Experiments
let platormAdminAIExperimentsPipelineAPI = backendURL + "/api/platform/admin/aiExperiments/pipeline/";
let platforGetPulseSurveyAISummaryAPI = backendURL + "/api/platform/get/pulseSurveyAISummary/";
let platformPOSTOnboardingSurveysAISummaryAPI = backendURL + "/api/platform/post/onboardingSurveysAISummary/";

// CCOaaS
let platformGetCCOaaSReportAPI = backendURL + "/api/platform/get/CCOaaSReport/";
let platformGetCCOaaSCompanyDetailsAIAnalysisAPI = backendURL + "/api/platform/get/CCOaaSCompanyDetailsAIAnalysis/";

// Profile and employee info
let platformGetProfileAPI = backendURL + "/api/platform/get/profile/";
let platformGetJobInfoAPI = backendURL + "/api/platform/get/jobInfo/";
let platformGetJobCompAPI = backendURL + "/api/platform/get/jobComp/";

// Inbox 
let platformPostInboxRequestAPI = backendURL + "/api/platform/post/inboxRequest/";
let platformGetInboxMessagesForEmployeeAPI = backendURL + "/api/platform/get/inboxMessages/";
let platformGetInboxMessagesForCompanyAPI = backendURL + "/api/platform/get/allInboxMessages/";

// Holidays
let platformGetTimeOffAPI= backendURL + "/api/platform/get/timeoff/";
let platformPostTimeOffAPI = backendURL + "/api/platform/post/timeoff/";
let platformPutTimeOffAPI = backendURL + "/api/platform/put/timeoff/";
let platformDeleteTimeOffAPI = backendURL + "/api/platform/delete/timeoff/";
let platformGetTimeOffPendingRequestsForManagerAPI = backendURL + "/api/platform/get/timeOffPendingRequests/";
let platformGetDaysOffHRReportAPI = backendURL + "/api/platform/get/daysOffHRReport/";
let platformGetTeamHolidayCalendarAPI = backendURL + "/api/platform/get/teamHolidayCalendar/";
let platformPutTimeOffAwardTimeOffToEmployeeAPI = backendURL + "/api/platform/put/timeOff/awardTimeOffToEmployee/";

// Time-off Punch
let platformGetTimeOffPunchStatusAPI = backendURL + "/api/platform/get/timeoffPunchStatus/";
let platformPostTimeOffPunchAPI = backendURL + "/api/platform/post/timeoffPunch/";
let platformGetTimeOffHRPunchReportAPI = backendURL + "/api/platform/get/timeoffHRPunchReport/";
let platformGetTimeOffPunchEmployeeReportAPI = backendURL + "/api/platform/get/timeOffPunchEmployeeReport/";
let platformPostTimeOffPunchSubmitMonthSheetAPI = backendURL + "/api/platform/post/timeOffPunchMonthSheet/";
let platformGetTimeOffPunchEmployeeSchedulesAPI = backendURL + "/api/platform/get/timeOffPunchEmployeeSchedules/";
let platformPostTimeOffPunchEmployeeScheduleAPI = backendURL + "/api/platform/post/timeOffPunchEmployeeSchedule/";
let platformPutTimeOffPunchEmployeeScheduleAPI = backendURL + "/api/platform/put/timeOffPunchEmployeeSchedule/";
let platformDeleteTimeOffPunchEmployeeScheduleAPI = backendURL + "/api/platform/delete/timeOffPunchEmployeeSchedule/";

// Performance Review module
let platformGetLivePerformanceCampaignsAPI = backendURL + "/api/platform/get/livePerformanceCampaigns/";
let platformPostPerformanceCampaignAPI = backendURL + "/api/platform/post/performanceCampaign/";
let platformPostFeedbackProvidersAPI = backendURL + "/api/platform/post/performanceSubmitProviders/";
let platformPostSelfFeedbackAPI = backendURL + "/api/platform/post/performanceSubmitSelfFeedback/";
let platformPostManagerFeedbackAPI = backendURL + "/api/platform/post/performanceSubmitManagerFeedback/";
let platformGetPerformanceFeedbackProvidersAPI =backendURL + "/api/platform/get/performanceFeedbackProviders/";
let platformDeleteFeedbackProviderAPI = backendURL + "/api/platform/delete/performanceFeedbackProvider/";
let platformGetPerformanceDraftsAPI = backendURL + "/api/platform/get/performanceDraft/";
let platformGetManagerPerformanceDraftsAPI = backendURL + "/api/platform/get/managerPerformanceDraft/";
let platformSubmitSelfFeedbackAPI = backendURL + "/api/platform/post/performanceSubmitReviewedSelfFeedback/";
let platformSubmitManagerFeedbackAPI = backendURL + "/api/platform/post/performanceSubmitReviewedManagerFeedback/";
let platformGetPerformanceReviewByStatusAPI = backendURL + "/api/platform/get/performanceReviewByStatus/";
let platformGetPerformancePeerFeedbackAPI = backendURL + "/api/platform/get/performanceFeedbackForPeer/";
let platformPostPeerFeedbackAPI = backendURL + "/api/platform/post/submitPeerFeedback/";
let platformGetPerformanceReviewStatusByCompanyAPI = backendURL + "/api/platform/get/livePerformanceReviewStatusByCompany/";
let platformGetPerformanceCampaignsHistoryAPI = backendURL + "/api/platform/get/performanceCampaignsHistory/";
let platformGetSpecificPerformanceReviewAPI = backendURL + "/api/platform/get/specificPerformanceReview/";
let platformGetPerformanceReviewCampaignsByCompanyAPI = backendURL + "/api/platform/get/performanceReviewCampaignsByCompany/";
let platformGetPerformanceReviewReportingDataPerCampaignAPI = backendURL + "/api/platform/get/performanceReviewReportingDataPerCampaign/";
let platformGetCSVDownloadPerformanceCampaignAPI = backendURL + "/api/platform/get/performanceReviewCSV/";

// Development module
let platformGetDevelopmentPlanAPI =  backendURL +  "/api/platform/get/developmentPlan/";
let platformPostDevelopmentPlanAPI = backendURL +  "/api/platform/post/developmentPlan/";

// Calibration module
let platformGetCalibrationCampaignsAPI = backendURL + "/api/platform/get/calibrationCampaigns/";
let platformGetCalibrationCampaignsHistoryAPI = backendURL + "/api/platform/get/calibrationCampaignsHistory/";
let platformPostCalibrationCampaignAPI = backendURL + "/api/platform/post/calibrationCampaign/";
let platformGetCalibrationsPerManagerAPI = backendURL + "/api/platform/get/calibrationsPerManager/";
let platformGetCalibrationsReportAPI = backendURL + "/api/platform/get/calibrationsReport/";

// Meetings
let platformPostMeetingAPI = backendURL + "/api/platform/post/submitMeeting/";
let platformGetOneOnOneHistoryManagerAPI = backendURL + "/api/platform/get/oneOnOneHistoryPerManager/";
let platformGetMeetingAPI = backendURL + "/api/platform/get/meeting/";
let platformGetOneOnOnesReportAPI = backendURL + "/api/platform/get/oneOnOnesReport/";
let platformDeleteMeetingAPI = backendURL + "/api/platform/delete/meeting/";

// Pending to-do's
let platformGetPendingToDosAPI = backendURL + "/api/platform/get/pendingToDos/";
let platformPutUpdateNotificationStatus = backendURL + "/api/platform/put/updateNotificationStatus/";

// Pulse module
let pulseLiveCampaignsPerManagerAPI = backendURL + "/api/pulse/get/liveCampaignsPerManager/";
let pulseLiveCampaignsAdminAPI = backendURL + "/api/pulse/get/liveCampaignsAdmin/";
let platformGetPulseHomeGraphFrontendDataAPI = backendURL + "/api/platform/get/pulseHomeGraphFrontendData/";
let platformGetPulseKeyIndicatorsAPI = backendURL + "/api/platform/get/pulseKeyIndicators/";
let pulseGetAllQuestionsAPI = backendURL + "/api/pulse/get/allQuestions/";
let platformUnauthenticatedPulseGetSurveyAPI = backendURL + "/api/pulse/get/liveSurvey/";
let pulsePostSurveyAnswerAPI = backendURL + "/api/pulse/post/surveyAnswers/";
let platformGetCSVDownloadCampaignAPI = backendURL + "/api/pulse/get/campaignCSV/";
let platformGetPulseCampaignsPerCompany = backendURL + "/api/pulse/get/campaignsPerCompany/";
let platformGetPulseCampaignIDAPI = backendURL + "/api/pulse/get/campaignID/";
let platformGetPulseCampaignIDAnswersDirectsAPI = backendURL + "/api/pulse/get/campaignIDAnswers/";
let platformGetPulseCampaignIDAnswersManagersAPI = backendURL + "/api/pulse/get/campaignIDAnswersManagers/";
let platformGetPulseCampaignIDAnswersOrgAPI = backendURL + "/api/pulse/get/campaignIDAnswersOrg/";
let platformPostPulseCampaignAPI = backendURL + "/api/pulse/post/createPulseCampaign/";
let platformUpdatePulseCampaignAPI = backendURL + "/api/pulse/post/updatePulseCampaign/";
let platformDeletePulseCampaignAPI = backendURL + "/api/pulse/delete/deletePulseCampaign/";
let platformPostCreateQuestionAPI = backendURL + "/api/pulse/post/createQuestion/";
let pulseGetPulseDailyCheckAPI = backendURL + "/api/pulse/get/pulseDailyCheck/";
let platformPulseGetDailyQuestionAPI = backendURL + "/api/pulse/get/dailyQuestion/";
let pulseAnswerAPI =  backendURL + "/api/pulse/post/employeeAnswer/";
let pulseAnswerAuthenticatedAPI =  backendURL + "/api/pulse/post/authenticatdEmployeeAnswer/";
let pulseReportingDataAPI = backendURL + "/api/pulse/get/reportingData/";
let platformGetPulseQuestionProfileAPI = backendURL + "/api/pulse/get/questionProfile/";
let platformGetPulseDriverProfileAPI = backendURL + "/api/pulse/get/driverProfile/";


// Recognition
let platformGetRecognitionDataForCompanyAPI = backendURL + "/api/platform/get/recognitionDataForCompany/";
let platformGetRecognitionLeaderboardsForCompanyAPI = backendURL + "/api/platform/get/recognitionLeaderboardsForCompany/";
let platformPostRecognitionAPI = backendURL + "/api/platform/post/submitRecognition/";
let platformGetPublicSingleRecognitionAPI = backendURL + "/api/platform/get/publicSingleRecognition/";
let platformPostPlus1RecognitionAPI = backendURL + "/api/platform/post/plus1Recognition/";

// Rewards
let platformGetRewardsDataForCompanyAPI = backendURL + "/api/platform/get/rewardsDataForCompany/";
let platformPostCashRewardOutForEmployeeAPI = backendURL + "/api/platform/post/cashRewardOutForEmployee/";


// Organigram
let platformGetOrganigramAPI = backendURL + "/api/platform/get/organigram/";
let platformGetOrgOfManagerAPI = backendURL + "/api/platform/get/orgOfManager/";
let platformPostNewEmployeeAPI = backendURL + "/api/platform/post/newEmployee/";
let platformPutEmployeeAPI = backendURL + "/api/platform/put/employee/";
let platformDeleteEmployeeAPI = backendURL + "/api/platform/delete/employee/";
let platformGetOrganigramCSVAPI = backendURL + "/api/platform/get/organigramCSV/";
let platformPostOrganigramReorganizationAPI = backendURL + "/api/platform/post/organigramReorganization/";
let platformGetOrganigramReorganizationHistoryAPI = backendURL +  "/api/platform/get/organigramReorganizationHistory/";


// Onboarding
let platformGetOnboardingEligibleCandidatesAPI = backendURL + "/api/platform/get/onboardingEligibleCandidates/";
let platformGetOnboardingPlanForEmployeeAPI =  backendURL +  "/api/platform/get/onboardingPlanForEmployee/";
let platformGetOnboardingPlanTemplatesAPI = backendURL + "/api/platform/get/onboardingPlanTemplates/";
let platformGetSpecificOnboardingPlanAPI = backendURL + "/api/platform/get/onboardingPlan/";
let platformPostOnboardingPlanAPI = backendURL + "/api/platform/post/onboardingPlan/";
let platformDeleteOnboardingPlanAPI = backendURL + "/api/platform/delete/onboardingPlan/";
let platformPostOnboardingTemplateAPI = backendURL + "/api/platform/post/onboardingTemplate/";
let platformPostOnboardingPlanSubsectionCompleteAPI = backendURL +  "/api/platform/post/onboardingPlanSubsectionComplete/";
let platformGetOnboardingReportingDataAPI = backendURL + "/api/platform/get/onboardingReportingData/";
let platformDeleteOnboardingPlanTemplateAPI = backendURL + "/api/platform/delete/onboardingTemplate/";


// Career path
let platformGetCareerJobGuidelinesAPI = backendURL + "/api/platform/get/careerJobGuidelines/";
let platformPostCareerJobGuidelineAPI = backendURL + "/api/platform/post/careerJobGuideline/";
let platformPutCareerJobGuidelineAPI = backendURL + "/api/platform/put/careerJobGuideline/";
let platformGetCareerPathForEmployeeAPI = backendURL + "/api/platform/get/careerPathForEmployee/";
let platformPostCareerPathForEmployeeAPI = backendURL + "/api/platform/post/careerPathForEmployee/";
let platformPutCareerPathForEmployeeAPI = backendURL + "/api/platform/put/careerPathForEmployee/";

let platformPostCareerConversationAPI = backendURL + "/api/platform/post/careerConversation/";

// Settings
let platformGetCompanyPreferencesAPI = backendURL + "/api/platform/get/companyPreferences/";
let platformPostSaveSettingsAPI = backendURL + "/api/platform/post/saveSettings/";

// Bot
let platformSubmitFeedbackFormAPI = backendURL + "/api/platform/post/submitFeedbackForm/";

// Impersonation 
let platformGetEmployeesOfCompanyAPI = backendURL + "/api/platform/get/employeesOfCompany/";
let platformGetEmployeesOfManagerAPI = backendURL + "/api/platform/get/employeesOfManager/";
let platformGetMeetingsByEmployeeAPI = backendURL + "/api/platform/get/meetingsOfEmployee/";
let platformPostUploadFile = backendURL + "/api/platform/post/uploadFile/";
let platformGetDownloadFileAPI = backendURL + "/api/platform/get/downloadFile/";
let platformGetPublicURLForFileAPI = backendURL + "/api/platform/get/publicURLForFile/";
let platformGetKeyKPIsForEmployeeAPI = backendURL + "/api/platform/get/keyKPIsForEmployee/";

// PLG
let platformPostSlackAuthResponseAPI = backendURL + "/api/platform/post/slackAuthResponse/";

// ------------------------------------------------------------------------------------------
// API - Product Led Growth
// ------------------------------------------------------------------------------------------
let slackExchangeTokenForCodeAPI = "https://slack.com/api/oauth.v2.access";
let slackClientSecret = process.env.REACT_APP_SLACK_CLIENT_SECRET;
let slackClientId = process.env.REACT_APP_SLACK_CLIENT_ID;

// ------------------------------------------------------------------------------------------
// Company configurations
// ------------------------------------------------------------------------------------------
let headerColor = "bg-green-200";
let secondaryColor = "bg-gray-200";
let companyFavicon = "https://www.dropbox.com/s/iqbutdfpt2gd1xn/TaxDown_favicon%402x.png?raw=1";
let companyLogoSmall = "https://kincode-prod-public.s3.eu-central-1.amazonaws.com/Logo-Kincode-h-navbar_dev.png";
let companyLogoLarge = "https://kincode-prod-public.s3.eu-central-1.amazonaws.com/Logo-Kincode-h-navbar_dev.png";
let companyColor = "#BBF7D0"; // Green-200
let companySecondaryColor = "#BFC6FF"; // Purple
let companyTerciaryColor= "#FFBF76"; // Yellow

// --------------------------------------------------------------------------------------------------------------
// Customer integrations
// --------------------------------------------------------------------------------------------------------------
// Kincode App multitenant
 let azureSandboxSecret = process.env.REACT_APP_AZURE_SECRET_ID;
 let azureSandboxClientID = process.env.REACT_APP_AZURE_CLIENT_ID;
 let azureSandboxTenant = process.env.REACT_APP_AZURE_TENANT_ID;
 let azureSandboxResource = '9a5d1bb8-595e-4dd6-8e2d-b1a88921d704';

let azureCloudInstance = 'https://login.microsoftonline.com';
let azureAuthorizationEndpoint = 'https://login.microsoftonline.com/common';
let azureGraphEndpoint = 'https://graph.microsoft.com';
let integrationCallbackURL = devEnvironment ? 'http://localhost:3000' : 'https://platform.kincode.app';

let googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

let amplitudeAPIKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

// --------------------------------------------------------------------------------------------------------------
// Toaster
// --------------------------------------------------------------------------------------------------------------
// Toaster error codes
let successToaster = 'bg-green-500';
let successToasterDark = 'bg-green-400';
let warningToaster = 'bg-yellow-500';
let warningToasterDark = 'bg-yellow-400';
let errorToaster = 'bg-red-500';
let errorToasterDark = 'bg-red-400';
let infoToaster = 'bg-blue-500';
let infoToasterDark = 'bg-blue-400';

let toasterTimeout = 3000;
let saveTimer = 60000;

// --------------------------------------------------------------------------------------------------------------
// Feedback popup
// --------------------------------------------------------------------------------------------------------------
let feedbackPopupChance = devEnvironment ? 100 : 60;
let feedbackPopupChancePunch = devEnvironment ? 100 : 30;

// --------------------------------------------------------------------------------------------------------------
// Notifications
// --------------------------------------------------------------------------------------------------------------

// Notification types
// Satisfaction
let notificationsSurvey = "NOTIFICATION_SURVEY";
// Performance reviews
let notificationsPeerFeedback = "NOTIFICATION_FEEDBACK";
let notificationsSelfPerformanceReview = "NOTIFICATION_SELF_PERFORMANCE_REVIEW";
let notificationsManagerPerformanceReview = "NOTIFICATION_MANAGER_PERFORMANCE_REVIEW";
let notificationsRequestFeedbackPending = "NOTIFICATION_REQUEST_FEEDBACK_PENDING";
// Development
let notificationsActionPlanForPerformaceReview = "NOTIFICATION_MANAGER_PERFORMANCE_REVIEW_DEVELOPMENT_PLAN";
// Calibration
let notificationsCalibrationForManagers = "NOTIFICATION_CALIBRATION_FOR_MANAGERS";
// Recognition
let notificationsRecognitionForManagers = "NOTIFICATION_RECOGNITION_FOR_MANAGERS";
// Meetings
let notificationsOneOnOne = "NOTIFICATION_ONE_ON_ONE";
// Onboarding
let notificationsOnboardingSelfPlan = "NOTIFICATION_ONBOARDING_SELF_PLAN";
let notificationsOnboardingPlanManager = "NOTIFICATION_ONBOARDING_PLAN_MANAGER";
let notificationsOnboardingPlanEmployee3P = "NOTIFICATION_ONBOARDING_PLAN_EMPLOYEE_3P";

let notificationsTimeOffRequest = "NOTIFICATION_TIME_OFF_REQUEST";

// EDQ
let notificationsEDQMissingManager = "NOTIFICATION_EDQ_MISSING_MANAGER";

// Statuses
let notificationStatusUnread = 'Unread';
let notificationStatusRead = 'Read';
let notificationStatusRejected = 'Rejected';
let notificationStatusCompleted = 'Completed';
let notificationStatusExpired = 'Expired';

// Priorities
let priorityPulseSurvey = 0;

let priorityPeerFeedback = 0;
let prioritySelfPerformanceReview = 0;
let priorityManagerPerformanceReview = 0;

let priorityActionPlanForPerformaceReview = 0;

let priorityOneOnOne = 0;

let priorityCalibration = 0;

let priorityOnboardingSelfPlan = 0;
let priorityOnboardingPlanManager = 0;
let priorityOnboardingPlanEmployee3P = 0;

let priorityTimeOff = 0;

// --------------------------------------------------------------------------------------------------------------
// OneOnOnes 
// --------------------------------------------------------------------------------------------------------------
let oneOnOneTypeMeeting = "Meeting";
let oneOnOneTypeDevelopmentPlan = "DevelopmentPlan";
let oneOnOneTypeCareerConversation = "CareerConversation";

// --------------------------------------------------------------------------------------------------------------
// Time off
// --------------------------------------------------------------------------------------------------------------
let daysOffLeaveTypeVacation = "Vacation";
let daysOffLeaveTypeSick = "Sick";
let daysOffLeaveTypePersonal = "Personal";
let daysOffLeaveTypeMaternity = "Maternity";
let daysOffLeaveTypeCarryOver = "Carry over";
let daysOffLeaveTypeUnpaid = "Unpaid";
let daysOffLeaveTypeBereavement = "Bereavement";
let daysOffLeaveTypeOther = "Other";
let daysOffLeaveTypeWFH = "WFH";
let daysOffLeaveTypeWFHSick = "WFH Sick";
let daysOffLeaveTypeWedding = "Wedding";

let daysOffStatusPending = "Pending";
let daysOffStatusApproved = "Approved";
let daysOffStatusRejected = "Rejected";
let daysOffStatusCancelled = "Cancelled";

// --------------------------------------------------------------------------------------------------------------
// Punch In/out
// --------------------------------------------------------------------------------------------------------------
let punchInOutDefaultPolicy = {
    "settings": {
      "in": "09:00",
      "out": "18:00",
      "restingTime": 1,
      "totalHours": 8
    }
  };

// --------------------------------------------------------------------------------------------------------------
// Onboarding plans
// --------------------------------------------------------------------------------------------------------------
let onboardingPlanStatusDraft = "Draft";
let onboardingPlanStatusPublished = "Published";
let onboardingPlanStatusNotStarted = "Not Started";
let onboardingPlanStatusInProgress = "In Progress";
let onboardingPlanStatusCompleted = "Completed";

// --------------------------------------------------------------------------------------------------------------
// KPI Cards 
// --------------------------------------------------------------------------------------------------------------
let KPIGraphAccelerator = "GraphAccelerator";

// --------------------------------------------------------------------------------------------------------------
// Audit Logs types
// --------------------------------------------------------------------------------------------------------------
let auditLogTypeLogin = "LOGIN";
let auditLogTypeLogout = "LOGOUT";
let auditLogTypePasswordChange = "PASSWORD_CHANGE";
let auditLogTypePasswordReset = "PASSWORD_RESET";
let auditLogTypePasswordResetRequest = "PASSWORD_RESET_REQUEST";

let auditLogTypeUserFeedback = "USER_FEEDBACK";

let auditLogTypeRecognitionPosted = "RECOGNITION_POSTED";
let auditLogTypeRecognitionReceived = "RECOGNITION_RECEIVED";

// Security
let auditLogTypeLoginAttemptWOUsernameOrPassword = "LOGIN_WO_USERNAME_OR_PASSWORD";
let auditLogTypeLoginNonExistingUsername = "LOGIN_NONEXISTING_USERNAME";
let auditLogTypeLoginIncorrectPassword = "LOGIN_INCORRECT_PASSWORD";
let auditLogTypePasswordResetNonexistingUsername = "PASSWORD_RESET_NONEXISTING_USERNAME";
let auditLogTypeNastyCrossCompanyRequest = "NASTY_CROSS_COMPANY_REQUEST";
let auditLogTypeNastyCrossEmployeeRequest = "NASTY_CROSS_EMPLOYEE_REQUEST";

// --------------------------------------------------------------------------------------------------------------
// Pulse defaults
// --------------------------------------------------------------------------------------------------------------
let pulseDefaultDrivers = [
        'pulse.drivers.satisfaction', // Satisfaction
        'pulse.drivers.purpose', // Purpose & meaning
        'pulse.drivers.engagement', // Engagement
        'pulse.drivers.leadership', // Leadership & communication
        'pulse.drivers.growth',// Growth
        'pulse.drivers.inclusion',// Inclusion & fairness
        'pulse.drivers.manager',// Management support
        'pulse.drivers.teamwork',// Teamwork
        'pulse.drivers.recognition',// Recognition & rewards
        'pulse.drivers.belonging',// Belonging
        'pulse.drivers.opinion',// Freedom of Opinion
        'pulse.drivers.innovation',// Innovation & agility
        'pulse.drivers.worklife',// Work-life balance
        'pulse.drivers.resources',// Resources & tools
        'pulse.drivers.organizational',// Organizational fit
      ];
      
// --------------------------------------------------------------------------------------------------------------
// File input types
// --------------------------------------------------------------------------------------------------------------
let maxFileSize = 15 * 1024 * 1024; // 15MB = 15 * 1024 * 1024
let allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
let allowedTypesPDF = ['application/pdf'];
let allowedTypesImages = ['image/jpeg', 'image/png'];
let allowedTypesCSV = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

// --------------------------------------------------------------------------------------------------------------
// Styles
// --------------------------------------------------------------------------------------------------------------
let formDropdownStyle = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        borderColor: state.isFocused ? companyColor : '#D1D5DB', // Tailwind colors: gray-600 and gray-300
        boxShadow: state.isFocused ? ('0 0 0 3px ' + companyColor) : '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)',
        '&:hover': {
            borderColor: state.isFocused ? '#4B5563' : '#9CA3AF', // Tailwind colors: gray-600 and gray-400
        },
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: '8px',
            border: '1px solid #D1D5DB', // Tailwind colors: gray-300
            // borderColor: state.isFocused ? '#D1D5DB' : '#D1D5DB', // Tailwind colors: gray-600 and gray-300
            boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
            textAlign: 'left',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#000000' : '#1F2937', // Tailwind colors: white and gray-800
            backgroundColor: state.isSelected ? companySecondaryColor : state.isFocused ? companyColor : '#FFFFFF',
        }),
};

let widgetDropdownStyle = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        backgroundColor: 'none',
        border: "none",
        cursor: 'pointer',
        // borderColor: state.isFocused ? companyColor : '#D1D5DB', // Tailwind colors: gray-600 and gray-300
        boxShadow: state.isFocused ? ('0 0 0 3px ' + companyColor) : provided.boxShadow,
        '&:hover': {
            borderColor: state.isFocused ? '#4B5563' : '#9CA3AF', // Tailwind colors: gray-600 and gray-400
            backgroundColor: '#e2e8f0',
            boxShadow: '0 0 8px 2px #e2e8f0',
        },
        ":focus": {
            // backgroundColor: "#e2e8f0",
            // boxShadow: '0 0 8px 2px #e2e8f0',
        },
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: '8px',
            // border: '1px solid #D1D5DB', // Tailwind colors: gray-300
            // borderColor: state.isFocused ? '#D1D5DB' : '#D1D5DB', // Tailwind colors: gray-600 and gray-300
            // boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#000000' : '#1F2937', // Tailwind colors: white and gray-800
            backgroundColor: state.isSelected ? companySecondaryColor : state.isFocused ? companyColor : '#FFFFFF',
        }),
    };

let graphsColor5 = '#00E5B1'; // color: #00E5B1; // carol: #007B55
let graphsColor4 = '#81BF82'; // color: #81BF82;
let graphsColor3 = '#FFED8A'; // color: #FFED8A; // carol: #E6A700
let graphsColor2 = '#FFBF76'; // color: #FFBF76; // carol: #FFECB3
let graphsColor1 = '#FD9163'; // color: #FD9163; // carol: #FF6F00
let graphsColor0 = '#FF6384'; // color: #FF6384;
let graphsColorNeutral = '#BFC6FF'; // color: #BFC6FF;
let graphsIdleColor = '#F1F2F5'; // color: #D1D5DB; // #F1F2F5


// --------------------------------------------------------------------------------------------------------------

module.exports = {
    devEnvironment, stagingEnvironment,
    
    // Backend URLs
    backendProdURL, backendDevURL,

    // Analytics
    amplitudeAPIKey,

    // Azure integration
    azureSandboxSecret, azureSandboxClientID, integrationCallbackURL, 
    // azureSandboxResource,
     azureSandboxTenant, azureCloudInstance,
    azureAuthorizationEndpoint, azureGraphEndpoint,

    // Google integration
    googleClientID,

    // Logging
    platformLogErrorAPI,

    // Authentication methods
    updatePasswordAPI,resetPasswordAPI,

    privateKey, securityLevelAdmin, securityLevelHR, securityLevelIC, securityLevelInterviewer, securityLevelManager, securityLevelSuperAdmin, securityLevelExec,

    loginAPI, authenticate, getKincodeTokenAPI, logoutAPI,

    // Admin
    platformAdminGetUserActivityAPI, platformAdminGetAllCompaniesAPI, platformAdminPostNewCompanyAPI, platformAdminGetEmployeesOfCompanyAPI,

    // AI Experiments
    platormAdminAIExperimentsPipelineAPI, platforGetPulseSurveyAISummaryAPI, platformPOSTOnboardingSurveysAISummaryAPI,

    // CCCOaaS
    platformGetCCOaaSReportAPI, platformGetCCOaaSCompanyDetailsAIAnalysisAPI,

    // Settings
    platformGetCompanyPreferencesAPI, platformPostSaveSettingsAPI,

    // Inbox
    platformPostInboxRequestAPI, platformGetInboxMessagesForEmployeeAPI, platformGetInboxMessagesForCompanyAPI,

    // Pulse methods
    pulseLiveCampaignsPerManagerAPI,platformGetPulseKeyIndicatorsAPI, platformGetCSVDownloadCampaignAPI,
    platformGetPulseHomeGraphFrontendDataAPI,
    pulseLiveCampaignsAdminAPI, pulseGetAllQuestionsAPI, platformUnauthenticatedPulseGetSurveyAPI, pulsePostSurveyAnswerAPI,
    platformGetPulseCampaignsPerCompany, platformGetPulseCampaignIDAPI,
    platformPostPulseCampaignAPI,platformGetPulseCampaignIDAnswersDirectsAPI,
    platformGetPulseCampaignIDAnswersManagersAPI, platformGetPulseCampaignIDAnswersOrgAPI,
    platformUpdatePulseCampaignAPI, platformDeletePulseCampaignAPI, 
    platformPostCreateQuestionAPI,
    pulseGetPulseDailyCheckAPI, platformPulseGetDailyQuestionAPI, pulseAnswerAPI, pulseAnswerAuthenticatedAPI,
    pulseReportingDataAPI,
    platformGetPulseQuestionProfileAPI, platformGetPulseDriverProfileAPI,

    // Profile methods
    platformGetProfileAPI, platformGetJobInfoAPI, platformGetJobCompAPI, 
    
    // Time-off methods
    platformGetTimeOffAPI, platformPostTimeOffAPI, platformPutTimeOffAPI, platformDeleteTimeOffAPI,
    platformGetTimeOffPendingRequestsForManagerAPI, platformGetDaysOffHRReportAPI,
    platformGetTeamHolidayCalendarAPI,
    platformPutTimeOffAwardTimeOffToEmployeeAPI,

    // Punch
    platformGetTimeOffPunchStatusAPI, platformPostTimeOffPunchAPI, platformGetTimeOffHRPunchReportAPI, platformGetTimeOffPunchEmployeeReportAPI,
    platformPostTimeOffPunchSubmitMonthSheetAPI,
    platformGetTimeOffPunchEmployeeSchedulesAPI, platformPostTimeOffPunchEmployeeScheduleAPI, platformPutTimeOffPunchEmployeeScheduleAPI, 
    platformDeleteTimeOffPunchEmployeeScheduleAPI,
     
    // Generic methods     
    platformGetEmployeesOfCompanyAPI,platformGetEmployeesOfManagerAPI, 
    platformPostUploadFile, platformGetDownloadFileAPI, platformGetPublicURLForFileAPI,
    platformGetKeyKPIsForEmployeeAPI,

    // PLG
    platformPostSlackAuthResponseAPI,

    // Performance methods
    platformGetLivePerformanceCampaignsAPI, platformPostPerformanceCampaignAPI, platformPostFeedbackProvidersAPI, platformPostSelfFeedbackAPI, platformPostManagerFeedbackAPI,
    platformGetPerformanceFeedbackProvidersAPI, platformGetPerformanceDraftsAPI, platformGetManagerPerformanceDraftsAPI,
    platformDeleteFeedbackProviderAPI,
    platformSubmitSelfFeedbackAPI, platformSubmitManagerFeedbackAPI,
    platformGetPerformanceReviewByStatusAPI, platformGetPerformancePeerFeedbackAPI,
    platformPostPeerFeedbackAPI, platformGetPerformanceReviewStatusByCompanyAPI , platformGetPerformanceCampaignsHistoryAPI,
    platformGetSpecificPerformanceReviewAPI,  
    platformGetPerformanceReviewCampaignsByCompanyAPI, platformGetPerformanceReviewReportingDataPerCampaignAPI, // Reporting
    platformGetCSVDownloadPerformanceCampaignAPI,

    // Notifications
    platformGetPendingToDosAPI, platformPutUpdateNotificationStatus,
    // Development
    platformGetDevelopmentPlanAPI, platformPostDevelopmentPlanAPI,

    // Calibration 
    platformGetCalibrationCampaignsAPI, platformGetCalibrationCampaignsHistoryAPI,
    platformPostCalibrationCampaignAPI,platformGetCalibrationsPerManagerAPI, platformGetCalibrationsReportAPI,
    
    // 1:1 methods
    platformPostMeetingAPI,platformGetOneOnOneHistoryManagerAPI, platformGetMeetingAPI, platformGetMeetingsByEmployeeAPI, platformGetOneOnOnesReportAPI,
    platformDeleteMeetingAPI,

    // Recognition 
    platformGetRecognitionDataForCompanyAPI, platformGetRecognitionLeaderboardsForCompanyAPI, platformPostRecognitionAPI,
    platformGetPublicSingleRecognitionAPI, platformPostPlus1RecognitionAPI,

    // Rewards
    platformGetRewardsDataForCompanyAPI, platformPostCashRewardOutForEmployeeAPI,

    // Organigram
    platformGetOrganigramAPI, platformGetOrgOfManagerAPI,
    platformPostNewEmployeeAPI, platformPutEmployeeAPI, platformDeleteEmployeeAPI,
    platformGetOrganigramCSVAPI, platformPostOrganigramReorganizationAPI, 
    platformGetOrganigramReorganizationHistoryAPI,

    // Onboarding
    platformGetOnboardingEligibleCandidatesAPI, platformGetSpecificOnboardingPlanAPI, platformPostOnboardingPlanAPI, platformDeleteOnboardingPlanAPI,
    platformGetOnboardingPlanForEmployeeAPI, platformGetOnboardingPlanTemplatesAPI,
    platformPostOnboardingTemplateAPI,
    platformPostOnboardingPlanSubsectionCompleteAPI, platformGetOnboardingReportingDataAPI,
    platformDeleteOnboardingPlanTemplateAPI,

    // Career
    platformGetCareerJobGuidelinesAPI, platformPostCareerJobGuidelineAPI, platformPutCareerJobGuidelineAPI,
    platformGetCareerPathForEmployeeAPI, platformPostCareerPathForEmployeeAPI, platformPutCareerPathForEmployeeAPI,

    platformPostCareerConversationAPI,

    // Bot
    platformSubmitFeedbackFormAPI,

    // --------------------------------------------------------------------------------------------------------------
    // PLG
    // --------------------------------------------------------------------------------------------------------------
    slackExchangeTokenForCodeAPI, slackClientSecret, slackClientId,

    // Company Personalization
    headerColor, secondaryColor, companyFavicon, companyLogoSmall, companyLogoLarge, companyColor, companySecondaryColor, companyTerciaryColor,

    // Toaster error codes
    successToaster,warningToaster, errorToaster,
    successToasterDark, warningToasterDark, errorToasterDark, infoToaster, infoToasterDark,
    toasterTimeout, saveTimer, 
    feedbackPopupChance, feedbackPopupChancePunch,

    // --------------------------------------------------------------------------------------------------------------
    // Notifications
    // --------------------------------------------------------------------------------------------------------------
    notificationsSurvey, notificationsPeerFeedback, notificationsOneOnOne, notificationsSelfPerformanceReview, notificationsManagerPerformanceReview,
    notificationsRequestFeedbackPending,
    notificationsActionPlanForPerformaceReview,notificationsCalibrationForManagers,
    notificationsRecognitionForManagers,
    
    // Onboarding
    notificationsOnboardingSelfPlan,
    notificationsOnboardingPlanManager, notificationsOnboardingPlanEmployee3P,

    // Time-off
    notificationsTimeOffRequest,
    
    // EDQ
    notificationsEDQMissingManager,

    // Statuses
    notificationStatusUnread, notificationStatusRead, notificationStatusRejected, notificationStatusCompleted, notificationStatusExpired,

    // Priorities
    priorityPulseSurvey, priorityPeerFeedback,
    prioritySelfPerformanceReview, priorityManagerPerformanceReview,
    priorityActionPlanForPerformaceReview,
    priorityOneOnOne,
    priorityCalibration,
    priorityOnboardingSelfPlan,
    priorityOnboardingPlanManager, priorityOnboardingPlanEmployee3P,
    priorityTimeOff,

    // --------------------------------------------------------------------------------------------------------------
    // Pulse defaults
    // --------------------------------------------------------------------------------------------------------------
    pulseDefaultDrivers,
    
    // --------------------------------------------------------------------------------------------------------------
    // 1:1s
    // --------------------------------------------------------------------------------------------------------------
    oneOnOneTypeMeeting, oneOnOneTypeDevelopmentPlan, oneOnOneTypeCareerConversation,

    // --------------------------------------------------------------------------------------------------------------
    // Time off
    // --------------------------------------------------------------------------------------------------------------
    daysOffStatusPending, daysOffStatusApproved, daysOffStatusRejected, daysOffStatusCancelled, 
    
    daysOffLeaveTypeVacation, daysOffLeaveTypeSick , daysOffLeaveTypePersonal, daysOffLeaveTypeMaternity, daysOffLeaveTypeCarryOver,
    daysOffLeaveTypeUnpaid ,daysOffLeaveTypeBereavement, daysOffLeaveTypeOther,
    daysOffLeaveTypeWFH, daysOffLeaveTypeWFHSick, daysOffLeaveTypeWedding,

    // --------------------------------------------------------------------------------------------------------------
    // Onboarding 
    // --------------------------------------------------------------------------------------------------------------
    onboardingPlanStatusDraft,  onboardingPlanStatusPublished,  onboardingPlanStatusNotStarted, onboardingPlanStatusInProgress, onboardingPlanStatusCompleted,

    // --------------------------------------------------------------------------------------------------------------
    // Punch In/out
    // --------------------------------------------------------------------------------------------------------------
    punchInOutDefaultPolicy,

    // --------------------------------------------------------------------------------------------------------------
    // Graphs
    // --------------------------------------------------------------------------------------------------------------
    KPIGraphAccelerator,

    // --------------------------------------------------------------------------------------------------------------
    // Logs audit
    // --------------------------------------------------------------------------------------------------------------
        // Audit logs types
    auditLogTypeLogin, auditLogTypeLoginAttemptWOUsernameOrPassword, auditLogTypeLoginIncorrectPassword, auditLogTypeLoginNonExistingUsername,
    auditLogTypeLogout, 
    auditLogTypePasswordResetRequest, auditLogTypePasswordResetNonexistingUsername,
    auditLogTypeRecognitionPosted, auditLogTypeRecognitionReceived,
    auditLogTypeNastyCrossCompanyRequest, auditLogTypeNastyCrossEmployeeRequest,

    // --------------------------------------------------------------------------------------------------------------
    // File input types
    // --------------------------------------------------------------------------------------------------------------
    maxFileSize, allowedTypes, allowedTypesPDF, allowedTypesImages, allowedTypesCSV,

    // --------------------------------------------------------------------------------------------------------------
    // Styles
    // --------------------------------------------------------------------------------------------------------------
    formDropdownStyle, widgetDropdownStyle, graphsColor5, graphsColor4, graphsColor3, graphsColor2, graphsColor1, graphsColor0, graphsColorNeutral,
     graphsIdleColor

}