// React
import React, {useState} from 'react';

// Common components
import Configs from '../Configs';
import { useAuth } from '../common/appContext';
import { useData } from '../common/dataContext';
import * as apiCalls from '../common/apiCalls';

// 3P
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import hmacSHA512 from 'crypto-js/hmac-sha512';

// Icons
import {BsGoogle, BsWindows} from 'react-icons/bs';

// Toaster and Loader
import Toaster from '../common/support/toaster';
import Loader from '../common/support/loader';

// Auth
import { useGoogleLogin, googleLogout} from '@react-oauth/google';
import Footer from '../common/Footer';


// import Footer from '../common/footer';

// ---------------------------------------------------------------------------------------------------
function Login (props) {
    const [t] = useTranslation();

    // Variables set up 
    const {setToken, setAuthenticated, isAuthenticationLoading, setIsAuthenticationLoading} = useAuth();
    const {companyID, setCompanyID, employeeID, setEmployeeID, setSecurityLevel} = useAuth();
    const {toasterMessage, setToasterMessage, getProfile, setProfile, setCompanyPreferences, setCompanyBackgroundImageURL} = useData();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [missingUsername, setMissingUsername] = useState(false);
    const [wrongPassword, setWrongPassword] = useState(false);
    const [missingInput, setMissingInput] = useState(false);
    const [wrongUsernameFormat, setWrongUsernameFormat] = useState(false);
    const [emptyUsername, setEmptyUsername] = useState(false);

    const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);

    const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------    
// useEffect(
//     () => {
        
//     },[ ]);

// ---------------------------------------------------------------------------------------------------------
// Google login methods
// ---------------------------------------------------------------------------------------------------------  
function logInWithGoolge () {
    // Start loader
    setIsAuthenticationLoading(true);
    // Log in with Google
    logInGoogle();
}

const logInGoogle = useGoogleLogin({
    onSuccess: codeResponse => {
        if (Configs.devEnvironment) console.log("[login.js][logInGoogle()] success: ",  codeResponse);
        props?.setIsGoogleAuthenticated(true);
        props?.getGoogleUserInfo(codeResponse.access_token);
    }
        ,
    // flow: 'auth-code',
    onError: error => {
        if (Configs.devEnvironment) console.error("[login.js][logInGoogle()] error: ",  error);
        setToasterMessage({message: t('error'), type: Configs.errorToaster});
        setIsAuthenticationLoading(false);
        setAuthenticated(false);
        props?.setIsGoogleAuthenticated(false);
    },
    onNonOAuthError: error => {
        if (Configs.devEnvironment) console.error("[login.js][logInGoogle()] onNonOAuthError: ",  error);
        setToasterMessage({message: t('error'), type: Configs.errorToaster});
        setIsAuthenticationLoading(false);
        setAuthenticated(false);
        props?.setIsGoogleAuthenticated(false);
    }
  });


function logOutGoogle () {
    setIsAuthenticationLoading(false);
    setAuthenticated(false);
    props?.setIsGoogleAuthenticated(false); 
    googleLogout();
    sessionStorage.clear();
}

// ---------------------------------------------------------------------------------------------------
// Data validation methods
// ---------------------------------------------------------------------------------------------------    
    // Login button was pressed - validate the input
    async function validateInput (event) {
        event.preventDefault();
        setMissingUsername(false);
        setWrongPassword(false);
        setMissingInput(false);
        setWrongUsernameFormat(false);
        setEmptyUsername(false);

        // Validate input
        // TO DO REGEXP EMAIL ONLY
        if (username !== '' && password !== '' && (emailRegExp.test(username))) {
            // All okay, proceed to login - clean-up fronted messages

            // Proceed to login
            logIn('bearer');
        } else {
            // missing input - update frontend messaging
            if (username === '' || password === '') setMissingInput(true);
            if (! emailRegExp.test(username)) setWrongUsernameFormat(true);
        }
    }

    // Password forgotten button was pressed
    async function forgotPassword () {
        setMissingUsername(false);
        setWrongPassword(false);
        setMissingInput(false);
        setWrongUsernameFormat(false);
        setEmptyUsername(false);

        // To-Do what to do if an employee forgot their password
        if (username === '' || (!emailRegExp.test(username))) {
            if (username === '') setEmptyUsername(true);
            if (!emailRegExp.test(username)) setWrongUsernameFormat(true);
            setShowPasswordResetMessage(false);

        } else {
            setEmptyUsername(false);
            setWrongUsernameFormat(false);
            requestPasswordReset();
        }
    }

// ---------------------------------------------------------------------------------------------------
// API calls 
// ---------------------------------------------------------------------------------------------------        
// Request password reset
async function requestPasswordReset () {
    if (!emailRegExp.test(username)) {
        setWrongUsernameFormat(true);
        return;
    }
    
    try {
        const result = await apiCalls.resetPassword(null, null, process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, {username: username});

        if (Configs.devEnvironment) console.log("[login.js][requestResetPassword()] result: ", result);

        if (result > 200) {
            setToasterMessage({message: t('password.reset.error'), type: Configs.errorToaster})
            // throw result
        } else {
            // We managed to get a response from server - inform user 
            setShowPasswordResetMessage(true);
            setToasterMessage({message: t('password.reset.requested'), type: Configs.successToaster})

            // Token was received, and we navigate to another page
            if (Configs.devEnvironment) console.log ("[login.js][logIn()] Body from resetPassword: ", result.body);
                    
            setMissingUsername(false);
            setWrongPassword(false);
        }

    } catch (error) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        console.error ("[login.js][requestResetPassword()] Error fetching data: ", error);
    }
}    

// Login button was pressed
async function logIn () {
        // Define body
        const requestBody = {
            username: username,
            password: hmacSHA512(password, Configs.privateKey).toString(),
            timestamp: new Date().toISOString
        }
        try {
            // Start loader
            setIsAuthenticationLoading(true);

            const response = await apiCalls.logIn(companyID, employeeID, process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, requestBody);

            // Handle response
            // Review response status
            if (response === 500) {
                setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
                setAuthenticated(false);
                setIsAuthenticationLoading(false);
            } else if (response === 422) {
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                setAuthenticated(false);
                setIsAuthenticationLoading(false);
                // setWrongPassword(true);
            } else if (response === 404) {
                setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
                setAuthenticated(false);
                setIsAuthenticationLoading(false);
            } else if (response === 403) {
                setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
                setAuthenticated(false);
                setIsAuthenticationLoading(false);
            } else if (response === 401) {
                setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
                setAuthenticated(false);
                setIsAuthenticationLoading(false);
                sessionStorage.clear();
                setWrongPassword(true);
                // window.location.reload();
            } else if (response === 400) {
                setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
                setAuthenticated(false);
                setIsAuthenticationLoading(false);
            } else {
                if (Configs.devEnvironment) console.log("[logn.js][logIn()] response: ", response);

                

                // Token was received, and we navigate to another page
                if (response?.bearerToken && response?.companyID && response?.employeeID){
                    if (Configs.devEnvironment) console.log("[logn.js][logIn()] requesting profile: ", response);
                    if(response?.csrfToken) getProfile(response?.employeeID, response?.companyID, response?.bearerToken, response?.csrfToken);
                    if (response?.bearerToken) setToken(response?.bearerToken);
                    if (response?.csrfToken) sessionStorage.setItem("csrfToken", CryptoJS.AES.encrypt(response.csrfToken.toString(), Configs.privateKey).toString());
                    setCompanyID(response?.companyID);
                    if (response?.companyID) sessionStorage.setItem("companyID", CryptoJS.AES.encrypt(response.companyID.toString(), Configs.privateKey).toString());
                    setEmployeeID(response?.employeeID);
                    if (response?.employeeID) sessionStorage.setItem("employeeID", CryptoJS.AES.encrypt(response.employeeID.toString(), Configs.privateKey).toString());
                    if (response?.profile) {
                        setProfile(response?.profile);
                        if (response?.profile?.roles?.securityLevels) setSecurityLevel(response?.profile?.roles?.securityLevels);
                        if (response?.profile?.companyPreferences) setCompanyPreferences(response?.profile?.companyPreferences);
                        if (response?.profile?.companyPreferences?.personalization?.backgroundImageURL) setCompanyBackgroundImageURL(response?.profile?.companyPreferences?.personalization?.backgroundImageURL);
                    }

                    setAuthenticated(true);
                    setIsAuthenticationLoading(false);
                }
                
                // Clear frontend messages
                setMissingUsername(false);
                setWrongPassword(false);                
            }
            
        } catch (error) {
            setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
            setAuthenticated(false);
            console.error ("[login.js][logIn()] Error fetching data: ", error);
        }        
    }
// ---------------------------------------------------------------------------------------------------
// Render methods
// ---------------------------------------------------------------------------------------------------    
        return (
            <div className='flex flex-col items-center justify-between h-screen w-full bg-white'>
             
                <div className="flex flex-col lg:items-center justify-center bg-gradient-to-b from-green-200 to-white w-full h-full">

                <div className='flex flex-row items-center justify-center w-full'>
                    {toasterMessage && <Toaster message={toasterMessage} timeout={Configs.toasterTimeout}  />}
                </div>

                    <div className='flex flex-col justify-center bg-white border border-gray-100 shadow-xl m-2 sm:m-20 pt-20 pb-20 p-12 lg:p-20 rounded lg:max-w-screen-xl'>

                        <div className='flex flex-col md:flex-row justify-center items-center'>
                            <div className="flex container w-[180px] h-[180px]">
                                <img className=" object-center object-contain" alt="Kincode-App-Logo" src={Configs.companyLogoLarge}
                                />
                            </div>
                            {/* <div id="kincode-title" className='flex items-start text-2xl font-mono font-bold'>Kincode</div> */}
                        </div>

                        {isAuthenticationLoading ? 
                            <Loader />
                            :
                        
                                <React.Fragment>
                                <div className='flex flex-col justify-center items-center mb-4 mt-8'>
                                    
                                        {/* Microsoft SSO */}
                                        <div className='flex flex-col text-center rounded bg-gray-200 w-full m-2 mt-4'>
                                            
                                            { props.isAzureAuthenticated ? 
                                                <button id="ms-sso-logout" className='text-center w-full p-2 shadow-xl hover:bg-gray-300 rounded-lg' onClick={props.logOutAzure}>
                                                    <div className='flex flex-row items-center text-center justify-center w-full'>
                                                        <span className='flex mr-3'><BsWindows/></span>
                                                        <span className='flex'>{t('logout')}</span>
                                                    </div>
                                                </button>
                                                
                                                : 
                                                
                                                <button id="ms-sso-login" className='text-center w-full p-2 shadow-xl hover:bg-gray-300 rounded-lg' onClick={props.logInAzure}>
                                                    <div className='flex flex-row items-center text-center  justify-center w-full'>
                                                        <span className='flex mr-3'><BsWindows/></span>
                                                        <span className='flex'>{t('login.microsoft')}</span>
                                                    </div>
                                                </button>
                                            }
                                        </div>

                                        <div className='flex flex-col text-center rounded bg-gray-200 w-full m-2 mt-4'>                                            
                                            { props.isGoogleAuthenticated ? 
                                                <button id="google-sso-logout" className='text-center w-full p-2 shadow-xl hover:bg-gray-300 rounded-lg' onClick={logOutGoogle}>
                                                        <div className='flex flex-row items-center text-center  justify-center w-full'>
                                                            <span className='flex mr-3'><BsGoogle/></span>
                                                            <span className='flex'>{t('logout')}</span>
                                                        </div>
                                                </button>
                                            :
                                                <button id="google-sso-login" className='text-center w-full p-2 shadow-xl hover:bg-gray-300 rounded-lg' onClick={logInWithGoolge}>
                                                    <div className='flex flex-row items-center text-center  justify-center w-full'>
                                                        <span className='flex mr-3'><BsGoogle/></span>
                                                        <span className='flex'>{t('login.google')}</span>
                                                    </div>
                                                </button>
                                            }       
                                
                                        </div>

                                        <div className='flex flex-row lg:w-1/2 w-full justify-between items-center'>
                                            <div className="mt-8 border-b border-gray-200 border-1 w-full">{" "}</div>
                                            <div className="mt-8 text-center w-full">{t('o')}</div>
                                            <div className="mt-8 border-b border-gray-200 border-1 w-full">{" "}</div>
                                        </div>

                                    </div>

                                <div className='flex justify-center'>
                                    <div className={missingInput || missingUsername || wrongPassword || wrongUsernameFormat || emptyUsername ? 'block flex italic text-red-500 mb-4 ' : 'invisible'}>
                                                {missingUsername ? t('username.does-not-exist') : null}
                                                {wrongPassword ? t('password.incorrect') : null}
                                                {missingInput ? t('field.missing') : null}
                                                {wrongUsernameFormat ? t('username.format.incorrect') : null}
                                                {emptyUsername ? t('username.empty') : null}
                                    </div>
                                </div>

                                

                                    <form onSubmit={validateInput} className='flex flex-col justify-center text-left items-center w-full'>

                                        <div className='flex flex-col sm:flex-row m-2 p-2'>
                                            <label className='flex p-2'> 👤 {t('username')}: </label>
                                            <input 
                                            id="username" 
                                            type="email" 
                                            placeholder="example@email.com" 
                                            className='input-field ml-2 sm:ml-4 '
                                            onChange = {(e) => setUsername(e.target.value)}
                                            required
                                            autoComplete="off"
                                            value ={username}
                                            ></input>
                                        </div>

                                        <div className={username === "" || username === null ? 'hidden' : 'flex flex-col sm:flex-row m-2 p-2'}>
                                            <label className='flex p-2'> 🔑 {t('password')}: </label>
                                            <input 
                                            id="password" 
                                            type="password" 
                                            placeholder="****" 
                                            autoComplete="off"
                                            className='input-field ml-2 sm:ml-4 '
                                            required
                                            onChange = {(e) => setPassword(e.target.value)}
                                            ></input>
                                        </div>

                                        <div className={username === "" || username === null ? 'hidden' : 'save-button w-full m-2 mt-4'}>
                                            <button id="submit" type="submit" className='text-center w-full' > {t('login')}</button>
                                        </div>

                                    </form>
                                    {/* Reset password */}
                                    <div className={username === "" || username === null ? 'hidden' : 'flex flex-col items-center justify-center text-center w-full'}>

                                        <div className=' flex flex-col justify-center text-center w-full  rounded m-2 hover:underline '>
                                            <button id="password-forgot" className='secondary-button items-center justify-center text-center w-full' onClick={forgotPassword}> {t('password.forgot')} </button>        
                                        </div>

                                        {showPasswordResetMessage ? 
                                            <div className='text-green-800 font-bold'>{t('password.reset.requested')}</div>
                                        : 
                                            null
                                        }
                                        
                                    </div>
                            </React.Fragment>
                        }

                    </div>
                    
                </div>
                <div className='flex flex-col items-center justify-center w-full'>
                    <Footer color={'bg-white'} />
                </div>
            </div>
        );
    }

export default Login;