import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------
//  Footer
// ----------------------------------------------------------------------
const Footer = ({
    color = null,
    // Add more props here
}) => {
    
    const [bgColor, setBgColor] = useState(color);

    const { t } = useTranslation();

    useEffect(() => {
        setBgColor(color);
    }, [color]);

// --------------------------------------------------------------------------------------------
// Render
// --------------------------------------------------------------------------------------------
    return (
        <footer className={`footer ${bgColor} w-full`}>
            <div className='border-t border-gray-200 w-full mt-8' />
            <div className="footer-links ">
                <a target="_blank" className='hover:underline' href="https://kincode.ai/terms-and-conditions">{t('footer.terms-and-conditions')}</a>
                <a target="_blank" className='hover:underline' href="https://kincode.ai/privacy-policy">{t('footer.privacy-policy')}</a>
                <a target="_blank" className='hover:underline' href="https://status.kincode.ai">{t('footer.status')}</a>
            </div>
            <div className="footer-trademark">
                <a target='_blank' href="https://www.kincode.ai">&copy; Kincode {new Date().getFullYear()}</a> 
            </div>
        </footer>
    );
};

export default Footer;