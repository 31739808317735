// React
import React, { createContext, useMemo, useState, useContext, useEffect, useCallback } from 'react';

// Configs and common
import { useAuth } from './appContext'; 
import Configs from '../Configs';
import * as apiCalls from './apiCalls';

// 3P
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

const DataContext = createContext();

// ------------------------------------------------------------------------------------------------
export const DataProvider = ({ children }) => {
    // 3P Translation  
    const { t } = useTranslation();  
    
    // Auth context
    const { correlationId, token, companyID, employeeID, securityLevel, setIsAuthenticationLoading } = useAuth();

    // Company personalization
    const [logoPublicURL, setLogoPublicURL] = useState(Configs.companyLogoSmall);

    // Messaging toaster
    const [toasterMessage, setToasterMessage] = useState(null);

    // Navbar collapse
    const navBarCollapsedAux = useMemo(() => {
      const storedData = sessionStorage.getItem("navBarCollapsed");
      return storedData || false;
    }, []);
    const [navBarCollapsed, setNavBarCollapsed] = useState(navBarCollapsedAux);

    // Feedback popup
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

    // Things to export --> add to value
    // Employee Profile
    const profileAux = useMemo(() => {
      const storedData = sessionStorage.getItem("employeeInfo");
      return storedData ? JSON.parse(CryptoJS.AES.decrypt(storedData, Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    }, []);
    const [profile, setProfile] = useState(profileAux);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [errorLoadingProfile, setErrorLoadingProfile] = useState(null);

    // Company Preferences
    const companyPreferencesAux = useMemo(() => {
      const storedData = sessionStorage.getItem("companyPreferences");
      return storedData ? JSON.parse(CryptoJS.AES.decrypt(storedData, Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
  }, []);
    const [companyPreferences, setCompanyPreferences] = useState(companyPreferencesAux);

    // Company Background Image URL
    const [companyBackgroundImageURL, setCompanyBackgroundImageURL] = useState(null);

    // Team data
    const teamDirectAux = useMemo(() => {
      const storedData = sessionStorage.getItem('teamDirects');
      return storedData ? JSON.parse(CryptoJS.AES.decrypt(storedData, Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    }, []);
    const teamOrgAux = useMemo(() => {
      const storedData = sessionStorage.getItem('teamOrg');
      return storedData ? JSON.parse(CryptoJS.AES.decrypt(storedData, Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    }, []);
    const companyOrgAux = useMemo(() => {
      const storedData = sessionStorage.getItem('companyMembers');
      return storedData ? JSON.parse(CryptoJS.AES.decrypt(storedData, Configs.privateKey).toString(CryptoJS.enc.Utf8)) : null;
    }, []);
    const [teamDirects, setTeamDirects] = useState(teamDirectAux);
    const [isDirectsLoading, setIsDirectsLoading] = useState(false);
    const [teamOrg, setTeamOrg] = useState(teamOrgAux);
    const [isOrgLoading, setIsOrgLoading] = useState(false);
    const [companyOrg, setCompanyOrg] = useState(companyOrgAux);    
    const [isCompanyLoading, setIsCompanyLoading] = useState(false);

    // Pending to-dos
    const [pendingToDos, setPendingToDos] = useState(null);
    const [isPendingToDosLoading, setIsPendingToDosLoading] = useState(false);
    const [keyKPIs, setKeyKPIs] = useState(null);
    const [isKeyKPIsLoading, setIsKeyKPIsLoading] = useState(false);

    // Selected profile - for context switching
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [isSelectedProfileLoading, setIsSelectedProfileLoading] = useState(false);

    // PERFORM
    // Performance - Personal data for widget
    const [personalPerformanceData, setPersonalPerformanceData] = useState(null);
    const [isPersonalPerformanceLoading, setIsPersonalPerformanceLoading] = useState(false);

    // My review
    const [personalPerformanceReviewDraft, setPersonalPerformanceReviewDraft] = useState(null);
    const [isPersonalPerformanceReviewDraftLoading, setIsPersonalPerformanceReviewDraftLoading] = useState(false);

    // Team review
    const [performanceReviewCampaigns, setPerformanceReviewCampaigns] = useState(null);
    const [livePerformanceCampaign, setLivePerformanceCampaign] = useState(false);
    const [isPerformanceLiveCampaignsLoading, setIsPerformanceLiveCampaignsLoading] = useState(false);
    const [performanceFeedbackProviders, setPerformanceFeedbackProviders] = useState(null);
    const [isPerformanceFeedbackProvidersLoading, setIsPerformanceFeedbackProvidersLoading] = useState(false);
    const [performanceReviewDraft, setPerformanceReviewDraft] = useState(null);
    const [isPerformanceReviewDraftLoading, setIsPerformanceReviewDraftLoading] = useState(false);
    const [performanceReviewManagerDraft, setPerformanceReviewManagerDraft] = useState(null);
    const [isPerformanceReviewManagerDraftLoading, setIsPerformanceReviewManagerDraftLoading] = useState(false);

    const [performanceReviewHistory, setPerformanceReviewHistory] = useState(null);
    const [isPerformanceReviewHistoryLoading, setIsPerformanceReviewHistoryLoading] = useState(false);

    // Calibration
    const [calibrationCampaign, setCalibrationCampaign] = useState(null);
    const [isCalibrationCampaignLoading, setIsCalibrationCampaignLoading] = useState(false);
    const [liveCalibrationCampaign, setLiveCalibrationCampaign] = useState(false);

    const [calibrationCampaignHistory, setCalibrationCampaignHistory] = useState(null);
    const [isCalibrationCampaignHistoryLoading, setIsCalibrationCampaignHistoryLoading] = useState(false);
    
    // ENGAGEMENT
    // Pulse
    const [pulseReportingData, setPulseReportingData] = useState(null);
    const [isPulseReportingDataLoading, setIsPulseReportingDataLoading] = useState(false);

    const [pulseKeyIndicators, setPulseKeyIndicators] = useState(null);
    const [isPulseKeyIndicatorsLoading, setIsPulseKeyIndicatorsLoading] = useState(false);

    const [pulseHomeGraphFrontendData, setPulseHomeGraphFrontendData] = useState(null);
    const [isPulseHomeGraphFrontendDataLoading, setIsPulseHomeGraphFrontendDataLoading] = useState(false);

    const [allPulseQuestions, setAllPulseQuestions] = useState(null);
    const [isAllPulseQuestionsLoading, setIsAllPulseQuestionsLoading] = useState(false);

    // Recognition
    const [companyRecognitionData, setCompanyRecognitionData] = useState(null);
    const [isCompanyRecognitionDataLoading, setIsCompanyRecognitionDataLoading] = useState(false);

    const [companyRecognitionLeaderboardData, setCompanyRecognitionLeaderboardData] = useState(null);
    const [isCompanyRecognitionLeaderboardDataLoading, setIsCompanyRecognitionLeaderboardDataLoading] = useState(false);

    // Recognition - Rewards
    const [companyRewardsData, setCompanyRewardsData] = useState(null);
    const [isCompanyRewardsDataLoading, setIsCompanyRewardsDataLoading] = useState(false);

    // Meetings
    const [meetingsData, setMeetingsData] = useState(null);
    const [isMeetingsDataLoading, setIsMeetingsDataLoading] = useState(false);
    const [oneOnOnesReport, setOneOnOnesReport] = useState(null);
    const [isOneOnOnesReportLoading, setIsOneOnOnesReportLoading] = useState(false);

    // DEVELOP
    // Onboarding
    const [onboardingReportingData, setOnboardingReportingData] = useState(null);
    const [isOnboardingReportingDataLoading, setIsOnboardingReportingDataLoading] = useState(false);

    const [onboardingPlanTemplates, setOnboardingPlanTemplates] = useState(null);
    const [isOnboardingPlanTemplatesLoading, setIsOnboardingPlanTemplatesLoading] = useState(false);
    const [onboardingEligibleEmployees, setOnboardingEligibleEmployees] = useState(null);
    const [isOnboardingEligibleEmployeesLoading, setIsOnboardingEligibleEmployeesLoading] = useState(false);

    // Career
    const [jobLevellingGuidelinesData, setJobLevellingGuidelinesData] = useState(null);
    const [isJobLevellingGuidelinesDataLoading, setIsJobLevellingGuidelinesDataLoading] = useState(false);

    const [isJobLevellingGuidelineLoading, setIsJobLevellingGuidelineLoading] = useState(false);

    const [selectedJobLevellingGuideline, setSelectedJobLevellingGuideline] = useState(null);

    const [isCareerPathLoading, setIsCareerPathLoading] = useState(false);
    const [isCareerConversationLoading, setIsCareerConversationLoading] = useState(false);

    // ESSENTIALS
    // Holidays
    const [holidaysTeamPendingRequests, setHolidaysTeamPendingRequests] = useState(null);
    const [isHolidaysTeamPendingRequestsLoading, setIsHolidaysTeamPendingRequestsLoading] = useState(false);

    const [teamHolidayCalendar, setTeamHolidayCalendar] = useState(null);
    const [isTeamHolidayCalendarLoading, setIsTeamHolidayCalendarLoading] = useState(false);

    const [selfHolidays, setSelfHolidays] = useState(null);
    const [isSelfHolidaysLoading, setIsSelfHolidaysLoading] = useState(false);

    // Punch
    const [punchHRReport, setPunchHRReport] = useState(null);
    const [isPunchHRReportLoading, setIsPunchHRReportLoading] = useState(false);
    const [punchesHistorySelf, setPunchesHistorySelf] = useState(null);
    const [isPunchesHistorySelfLoading, setIsPunchesHistorySelfLoading] = useState(false);
    const [punchEmployeeReport, setPunchEmployeeReport] = useState(null);
    const [isPunchEmployeeReportLoading, setIsPunchEmployeeReportLoading] = useState(false);

    // Organigram
    const [organigramData, setOrganigramData] = useState(null);
    const [isOrganigramDataLoading, setIsOrganigramDataLoading] = useState(false);

    // SUPER ADMIN
    const [selectedCompany, setSelectedCompany] = useState(null);
    // Company
    const [CCOaaSReport, setCCOaaSReport] = useState(null);
    const [isCCOaaSReportLoading, setIsCCOaaSReportLoading] = useState(false);

    const [CCOaaScompanyDetailsAIAnalysis, setCCOaaSCompanyDetailsAIAnalysis] = useState(null);
    const [isCCOaaSAICompanyDetailsAnalysisLoading, setIsCCOaaSAICompanyDetailsAnalysisLoading] = useState(true);
   

// ---------------------------------------------------------------------------------------------------
  // Toaster methods
  // ---------------------------------------------------------------------------------------------------    
  const closeToaster = () => {
    setToasterMessage(null);
  };

// ------------------------------------------------
// API calls
// ------------------------------------------------
const getPublicLogoURL = useCallback(async () => {
    try {
      const response = await apiCalls.getPublicURLForFile(companyID, employeeID, token, companyPreferences?.personalization?.logoSmall, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getPublicLogoURL()] response: ", response);
      setLogoPublicURL(response?.url);
    } catch (error) {
      console.error("[dataContext.js][getPublicLogoURL()] Error fetching data: ", error);
    }
}, [companyID, employeeID, token, companyPreferences, correlationId]);

const getPublicBackgroundImageURL = useCallback(async () =>  {
    try {
      const response = await apiCalls.getPublicURLForFile(companyID, employeeID, token, companyPreferences?.personalization?.backgroundImageURL, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getPublicBackgroundImageURL()] response: ", response);
      setCompanyBackgroundImageURL(response?.url);
    } catch (error) {
      console.error("[dataContext.js][getPublicBackgroundImageURL()] Error fetching data: ", error);
    }
}, [companyID, employeeID, token, companyPreferences, correlationId]);

// ------------------------------------------------
async function getProfile (employeeIDAux, companyIDAux, bearerToken, csrfToken, correlationId = null) {
    // Set Loading status
    if (selectedProfile) setIsSelectedProfileLoading(true);
    if (!selectedProfile) setIsProfileLoading(true);

    if (Configs.devEnvironment) console.log ("[dataContext.js][getProfile()] request: ", employeeIDAux, companyIDAux, token ? token : bearerToken);

    // fetch from server      
    fetch(Configs.platformGetProfileAPI + "?employeeID=" + (employeeIDAux ? employeeIDAux : employeeID) + "&companyID=" + (companyIDAux ? companyIDAux : companyID), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${token ? token : bearerToken}`, // notice the Bearer before your token
        'X-Correlation-ID': correlationId,
        // 'X-CSRF-TOKEN': csrfToken
      }
    })
    .then(response => {
        setIsProfileLoading(false);
        setIsSelectedProfileLoading(false);

        // Clean up loading variable on Auth
        setIsAuthenticationLoading(false);

        if (response.status === 200) {

            // Found to-dos
            response.json()
            .then (body => {
                if (Configs.devEnvironment) console.log ("[dataContext.js][getProfile()] Get Employee Info: ", body);
                if (body) {

                    if (body.employeeInfo.employeeID === employeeID) {
                        // sessionStorage.setItem("roles", CryptoJS.AES.encrypt(JSON.stringify(body.roles), Configs.privateKey).toString());
                        sessionStorage.setItem("employeeInfo", CryptoJS.AES.encrypt(JSON.stringify(body.employeeInfo), Configs.privateKey).toString());
                        sessionStorage.setItem('employeeProfile', CryptoJS.AES.encrypt(JSON.stringify(body), Configs.privateKey).toString());
                        if (body?.managerInfo) sessionStorage.setItem("managerInfo", CryptoJS.AES.encrypt(JSON.stringify(body.managerInfo), Configs.privateKey).toString());
                        sessionStorage.setItem("companyInfo", CryptoJS.AES.encrypt(JSON.stringify(body.companyInfo), Configs.privateKey).toString());
                        sessionStorage.setItem("companyPreferences", CryptoJS.AES.encrypt(JSON.stringify(body.companyPreferences), Configs.privateKey).toString());
                        // sessionStorage.setItem("activeModules", CryptoJS.AES.encrypt(JSON.stringify(body.companyPreferences.activeModules), Configs.privateKey).toString());
                        sessionStorage.setItem("securityLevel", CryptoJS.AES.encrypt(JSON.stringify(body.roles.securityLevels), Configs.privateKey).toString());             
                        
                        // Update state
                        setProfile(body);
                        // setSecurityLevel(body.roles.securityLevels); // REVIEW IF REMOVING THIS BROKE ANYTHING
                        setCompanyPreferences(body?.companyPreferences);
                        setCompanyBackgroundImageURL(body?.companyPreferences?.personalization?.backgroundImageURL);
                    } else {
                        // We are looking for a different profile
                        if (!(selectedProfile?.employeeID === body?.employeeInfo?.employeeID)) setSelectedProfile(body);
                    }
                }
            })
            .catch(error => {
            console.error("[dataContext.js][getProfile()] Error parsing response JSON", error);
            });
        } else if (response.status === 204) {
            // No profile found
        } else if (response.status === 400) {
          // Bad request
          setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
        } else if (response.status === 401) {
            // Unauthenticated
            // setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        } else if (response.status === 403) {
            // Unauthorized
            // setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
        } else if (response.status === 404) {
            // No results
            // setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
        } else if (response.status === 422) {
            // Data validation error
            //   setToasterMessage({message: t('error'), type: Configs.errorToaster});
        } else if (response.status === 500) {
            // Something went wrong - server error
        } else {
            // Something went wrong
            throw response;
        }
    })
    .catch((error) => {
      console.error("[dataContext.js][getProfile()] Error fetching data: ", error);
    //   setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
      setIsProfileLoading(false);
      setIsSelectedProfileLoading(false);
    });
}

// Team
// Get team members, only directs
async function getMyDirectTeam () {
    setIsDirectsLoading(true);

      // fetch from server      
      fetch(Configs.platformGetEmployeesOfManagerAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          // X-Correlation-ID header if available
          'X-Correlation-ID': correlationId
        }})
          .then((response) => {
            setIsDirectsLoading(false);
          // console.log (response);
            if (response.status === 200) {
                response.json()
                .then ( body => {
                    if (body.length > 0) {
                        if (Configs.devEnvironment) console.log ("[dataContext.js][getMyDirectTeam()] Directs: ", body);
                        sessionStorage.setItem('teamDirects', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                        setTeamDirects(body);
                    }
                })
                .catch(error => {
                    // setToasterMessage({message: t('pulse.load.warning'), type: Configs.errorToaster});
                    console.error("[dataContext.js][getMyDirectTeam()] Error parsing response JSON", error);
                });
            } else if (response.status === 204) {
                // No profile found
            } else if (response.status === 400) {
              // Bad request
            //   setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Unauthenticated
                // setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            } else if (response.status === 403) {
                // Unauthorized
                // setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            } else if (response.status === 404) {
                // No results
                // setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else if (response.status === 422) {
                // Data validation error
                //   setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 500) {
                // Something went wrong - server error
            } else {
                // Something went wrong
                throw response;
            }
          })
          .catch((error) => {
            console.log("[dataContext.js][getMyDirectTeam()] Error fetching data: ", error);
            // setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            setIsDirectsLoading(false);
          });
  }

  async function getMyTeamOrg () {
      setIsOrgLoading(true);

      // fetch from server      
      fetch(Configs.platformGetOrgOfManagerAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          'X-Correlation-ID': correlationId
        }})
          .then((response) => {
            setIsOrgLoading(false);
          
            if (response.status === 200) {
                response.json()
                .then ( body => {
                if (Configs.devEnvironment) console.log("[dataContext.js][getMyTeamOrg()] Org: ", body);
                        sessionStorage.setItem('teamOrg', CryptoJS.AES.encrypt(JSON.stringify(body[0]?.allSubordinates),Configs.privateKey).toString());
                        setTeamOrg(body[0]?.allSubordinates);
                        // populateManagersOptions(body[0]?.allSubordinates);
                })
                .catch(error => {
                    console.error("Error parsing response JSON", error)
                    setIsOrgLoading(false);
                });
            } else if (response.status === 204) {
                // No profile found
            } else if (response.status === 400) {
              // Bad request
            //   setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Unauthenticated
                // setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            } else if (response.status === 403) {
                // Unauthorized
                // setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            } else if (response.status === 404) {
                // No results
                // setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else if (response.status === 422) {
                // Data validation error
                //   setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 500) {
                // Something went wrong - server error
            } else {
                // Something went wrong
                throw response;
            }
          })
  
          .catch((error) => {
          console.log("[dataContext.js][getMyTeamOrg()] Error fetching data: ", error);
        //   setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
          setIsOrgLoading(false);
          });
  }

  // Get team members, only directs
  async function getMyCompanyOrg () {
    setIsCompanyLoading(true);

      // fetch from server      
      fetch(Configs.platformGetEmployeesOfCompanyAPI + "?employeeID=" + employeeID + "&companyID=" + companyID, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          'X-Correlation-ID': correlationId
        }})
          .then((response) => {
            setIsCompanyLoading(false);
            if (response.status === 200) {
                // Username and password mathched in the database - get Token and save it in the session
                response.json()
                .then ( body => {
                // if (Configs.devEnvironment) console.log("Body: ", body);
                    if (body.length > 0) {
                        // Add manager name to each employee
                        body?.forEach(employee => {
                            if (employee?.managerInfo) {
                                const manager = employee?.managerInfo?.name + " " + employee?.managerInfo?.surname;
                                if (manager) employee.manager = manager;
                            }
                        });
                        setCompanyOrg(body);
                        sessionStorage.setItem('companyOrg', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                        sessionStorage.setItem('companyMembers', CryptoJS.AES.encrypt(JSON.stringify(body),Configs.privateKey).toString());
                    }
                })
                .catch(error => {
                    console.error("[performanceTeam.js][getMyCompanyOrg()] Error parsing response JSON", error)
                });
            } else if (response.status === 204) {
                // No profile found
            } else if (response.status === 400) {
              // Bad request
            //   setToasterMessage({message: t('pulse.load.warning'), type: Configs.warningToaster});
            } else if (response.status === 401) {
                // Unauthenticated
                // setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
            } else if (response.status === 403) {
                // Unauthorized
                // setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
            } else if (response.status === 404) {
                // No results
                // setToasterMessage({message: t('pulse.load.success.empty'), type: Configs.successToaster});
            } else if (response.status === 422) {
                // Data validation error
                //   setToasterMessage({message: t('error'), type: Configs.errorToaster});
            } else if (response.status === 500) {
                // Something went wrong - server error
            } else {
                // Something went wrong
                throw response;
            }
          })
          .catch((error) => {
            console.log("[dataContext.js][getMyCompanyOrg()] Error fetching data: ", error);
            // setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster})
            setIsCompanyLoading(false);
          });
  }

  // ------------------------------------------------
  // HOME 
  // ------------------------------------------------
  async function getPendingToDos () {

      // Mark as loading
      setIsPendingToDosLoading(true);
      
      try {
        let response = await apiCalls.getPendingToDos(companyID, employeeID, token, correlationId);
        setPendingToDos(response);

        setIsPendingToDosLoading(false);

      } catch (error) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
        console.error("[Home.js][getPendingToDos()] Error fetching data: ", error);
        setIsPendingToDosLoading(false);
      }
  }

  async function getKeyKPIs () {

    // Mark as loading
    setIsKeyKPIsLoading(true);

    try {
      let response = await apiCalls.getKeyKPIs(companyID, employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getKeyKPIs()] response: ", response);
      setKeyKPIs([...response]);
    
      setIsKeyKPIsLoading(false);

    } catch (error) {
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      console.error("[Home.js][getKeyKPIs()] Error fetching data: ", error);
      setIsKeyKPIsLoading(false);
    }
  }

// ------------------------------------------------
// TIME OFF / DAYS OFF / HOLIDAYS
// ------------------------------------------------
  async function getHolidaysTeamPendingRequests () {

    try {
      // Set loading status
      setIsHolidaysTeamPendingRequestsLoading(true);

      const response = await apiCalls.getHolidaysTeamPendingRequests(companyID, employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getHolidaysTeamPendingRequests()] response: ", response);
    
      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getHolidaysTeamPendingRequests()] response: ", response);
        if (response.length > 0) setHolidaysTeamPendingRequests(response);
      }

      // Resolve loading status
      setIsHolidaysTeamPendingRequestsLoading(false);

    } catch (error) {
      console.error("[daysOffTeam.js][getPendingRequestsForManager()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsHolidaysTeamPendingRequestsLoading(false);
    }
    
  }

  async function getTeamHolidayCalendar () {
    try {
      // Set loading status
      setIsTeamHolidayCalendarLoading(true);

      const response = await apiCalls.getTeamHolidayCalendar(companyID, employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getTeamHolidayCalendar()] response: ", response);

      // Resolve loading status
      setIsTeamHolidayCalendarLoading(false);
    
      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getTeamHolidayCalendar()] response: ", response);
        if (response.length > 0) setTeamHolidayCalendar(response);
      }


 
    }
    catch (error) {
      console.error("[dataContext.js][getTeamHolidayCalendar()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsTeamHolidayCalendarLoading(false);
    }
  }

async function getSelfHolidays () {
    try {
      // Set loading status
      setIsSelfHolidaysLoading(true);

      const response = await apiCalls.getHolidaysForEmployee(companyID, employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getSelfHolidays()] response: ", response);

      // Resolve loading status
      setIsSelfHolidaysLoading(false);

      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getSelfHolidays()] response: ", response);
        if (response || response.length > 0) {
          setSelfHolidays(response);
        }
      }

    } catch (error) {
      console.error("[dataContext.js][getSelfHolidays()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsSelfHolidaysLoading(false);
    }
  }
  

  // ------------------------------------------------
  // PERFORMANCE
  // ------------------------------------------------
  async function getPersonalPerformanceData () {
    try {
      // Set loading status
      setIsPersonalPerformanceLoading(true);

      const response = await apiCalls.getPersonalPerformanceData(selectedProfile ? selectedProfile?.companyID : companyID,
         selectedProfile ? selectedProfile?.employeeID : employeeID,
          token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getPersonalPerformanceData()] response: ", response);

      // Resolve loading status
      setIsPersonalPerformanceLoading(false);
    
      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getPersonalPerformanceData()] response: ", response);
        if (response.length > 0) transformPersonalPerformanceData(response[0]);

        if(Configs.devEnvironment) console.log("[dataContext.js][getPersonalPerformanceData()] response: ", response);
      }
    } catch (error) {
      console.error("[dataContext.js][getPersonalPerformanceData()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPersonalPerformanceLoading(false);
    }
  }

  function transformPersonalPerformanceData (performanceDataAux) {
    // Iterate through 
    let transformedData = {};
  
    performanceDataAux?.values?.forEach(value => {
      var valueTrimmed = value?.value.replace(/\s/g, '');
  
      transformedData[valueTrimmed] = parseFloat(value?.selected);
      
    });
  
    if (Configs.devEnvironment) console.log ("[dataContext.js][transformPersonalPerformanceData()] transformedData: ", transformedData);
  
    setPersonalPerformanceData(transformedData);
  
  }

  async function getLivePerformanceCampaigns () {

    try {
      // Set loading status
      setIsPerformanceLiveCampaignsLoading(true);

      const response = await apiCalls.getLivePerformanceCampaigns(selectedProfile ? selectedProfile?.companyID : companyID,
        selectedProfile ? selectedProfile?.employeeID : employeeID,
         token, correlationId);

      if (Configs.devEnvironment) console.log("[dataContext.js][getLivePerformanceCampaigns()] response: ", response);

      // Resolve loading status
      setIsPerformanceLiveCampaignsLoading(false);
    
      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else if (response === 204) {
          setLivePerformanceCampaign(false);
          setPerformanceReviewCampaigns(null);
          setPerformanceFeedbackProviders(null);
          setPerformanceReviewDraft(null);
          setPerformanceReviewManagerDraft(null);
      } else {
        // 200 OK
        if (Configs.devEnvironment) console.log("[dataContext.js][getLivePerformanceCampaigns()] response: ", response);
        if (response.length > 0) {
          setLivePerformanceCampaign(true);
          setPerformanceReviewCampaigns(response);
          if (response[0]?._id) {
            getPerformanceFeedbackProviders(response[0]?._id);
            getPersonalPerformanceReviewDraft(response[0]?._id);
            // getPerformanceReviewDraft(response[0]?._id);
            // if (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) getPerformanceReviewManagerDraft(response[0]?._id, selectedProfile?.companyID, selectedProfile?.employeeID, token);
          }
        } else {
          setLivePerformanceCampaign(false);
          setPerformanceReviewCampaigns(null);
        }
      }  
      
    } catch (error) {
      console.error("[performanceHome.js][getPerformanceLiveCampaigns()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPerformanceLiveCampaignsLoading(false);
    }

  }

  async function getPerformanceReviewHistory () {
    try {
      // Set loading status
      setIsPerformanceReviewHistoryLoading(true);

      const response = await apiCalls.getPerformanceReviewHistory(selectedProfile ? selectedProfile?.companyID : companyID
        , selectedProfile ? selectedProfile?.employeeID : employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getPerformanceReviewHistory()] response: ", response);

      // Resolve loading status
      setIsPerformanceReviewHistoryLoading(false);

      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else if (response === 204) {
          setPerformanceReviewHistory(null);
      } else {
        // 200 OK
        if (Configs.devEnvironment) console.log("[dataContext.js][getPerformanceReviewHistory()] response: ", response);
        if (response.length > 0) setPerformanceReviewHistory(response);

      }
    } catch (error) {
      console.error("[performanceHome.js][getPerformanceReviewHistory()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPerformanceReviewHistoryLoading(false);
    }
  }

  async function getPerformanceFeedbackProviders (campaignID) {
    try {
      // Set loading status
      setIsPerformanceFeedbackProvidersLoading(true);

      const response = await apiCalls.getPerformanceFeedbackProviders(selectedProfile ? selectedProfile?.companyID : companyID
        , selectedProfile ? selectedProfile?.employeeID : employeeID, token, campaignID, correlationId);

      if (Configs.devEnvironment) console.log("[dataContext.js][getFeedbackProviders()] response: ", response);

      // Resolve loading status
      setIsPerformanceFeedbackProvidersLoading(false);

      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else if (response === 204) {
        setPerformanceFeedbackProviders([]);
      } else {
        // 200 OK
        if (Configs.devEnvironment) console.log("[dataContext.js][getFeedbackProviders()] response: ", response);
        if (response[0]?.feedbackProviders?.length > 0) {
          setPerformanceFeedbackProviders(response[0]?.feedbackProviders);
        } else {
          setPerformanceFeedbackProviders([]);
        }
      }

    } catch (error) {
      console.error("[performanceHome.js][getFeedbackProviders()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPerformanceFeedbackProvidersLoading(false);
    }
  }

  async function getPersonalPerformanceReviewDraft(campaignID) {
    // Avoid calling the function if params are missing
    if (!companyID || !employeeID || !token || !campaignID) return;

    try {
      // Set loading status
      setIsPersonalPerformanceReviewDraftLoading(true);

      const response = await apiCalls.getPerformanceReviewDraft(companyID, employeeID, token, campaignID, correlationId);

      if (Configs.devEnvironment) console.log("[dataContext.js][getPersonalPerformanceReviewDraft()] response: ", response);

      setIsPersonalPerformanceReviewDraftLoading(false);

      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else if (response === 204) {
        setPersonalPerformanceReviewDraft(null);
      } else {
        // 200 OK
        if (response.length > 0) {
          setPersonalPerformanceReviewDraft(response);
        } else {
          setPersonalPerformanceReviewDraft(null);
        }
      }
  } catch (error) {
    console.error("[performanceHome.js][getPersonalPerformanceReviewDraft()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsPersonalPerformanceReviewDraftLoading(false);
    setPersonalPerformanceReviewDraft(null);
  }
}

  async function getPerformanceReviewDraft(campaignIDAux, companyIDAux, employeeIDAux) {
    // Avoid calling the function if params are missing
    if (!companyIDAux || !campaignIDAux || !token || !employeeIDAux) return;

    try {
      // Set loading status
      setIsPerformanceReviewDraftLoading(true);

      const response = await apiCalls.getPerformanceReviewDraft(companyIDAux, employeeIDAux, token, campaignIDAux, correlationId);

      if (Configs.devEnvironment) console.log("[dataContext.js][getPerformanceReviewDraft()] response: ", response);

      // Resolve loading status
      setIsPerformanceReviewDraftLoading(false);

      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else if (response === 204) {
        setPerformanceReviewDraft(null);
      } else {
        // 200 OK
        if (response.length > 0) {
          setPerformanceReviewDraft(response);
        } else {
          setPerformanceReviewDraft(null);
        }
      }
  } catch (error) {
    console.error("[performanceHome.js][getPerformanceReviewDraft()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsPerformanceReviewDraftLoading(false);
  }
}

async function getPerformanceReviewManagerDraft(campaignID, companyID, employeeID, managerID) {
  try {
    // Set loading status
    setIsPerformanceReviewManagerDraftLoading(true);

    const response = await apiCalls.getPerformanceReviewManagerDraft(companyID, employeeID, token, campaignID, managerID, correlationId);

    if (Configs.devEnvironment) console.log("[dataContext.js][getPerformanceReviewManagerDraft()] response: ", response);

    // Resolve loading status
    setIsPerformanceReviewManagerDraftLoading(false);

    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else if (response === 204) {
      setPerformanceReviewManagerDraft(null);
    } else {
      // 200 OK
      if (Configs.devEnvironment) console.log("[dataContext.js][getPerformanceReviewManagerDraft()] response: ", response);
      if (response.length > 0) {
        setPerformanceReviewManagerDraft(response);
      } else {
        setPerformanceReviewManagerDraft(null);
      }
    }
} catch (error) {
  console.error("[performanceHome.js][getPerformanceReviewManagerDraft()] Error fetching data: ", error);
  setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
  setIsPerformanceReviewDraftLoading(false);
}
}

// ------------------------------------------------
// Calibration
// ------------------------------------------------
async function getCalibrationData() {
  try {
    // Set loading status
    setIsCalibrationCampaignLoading(true);

    const response = await apiCalls.getCalibrationData(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getCalibrationData()] response: ", response);

    // Resolve loading status
    setIsCalibrationCampaignLoading(false);

    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else if (response === 204) {
        setCalibrationCampaign(null);
        setLiveCalibrationCampaign(false);
    } else {
      // 200 OK
      if (Configs.devEnvironment) console.log("[dataContext.js][getCalibrationData()] response: ", response);
      if (response.length > 0) {
        setCalibrationCampaign(response[0]);
        setLiveCalibrationCampaign(true);
        return response[0];
      } else {
        setCalibrationCampaign(null);
        setLiveCalibrationCampaign(false);
      }
    }
  } catch (error) {
    console.error("[calibrationHome.js][getCalibrationData()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsCalibrationCampaignLoading(false);
    setCalibrationCampaign(null);
  }

}

async function getCalibrationHistory() {
  try {
    // Set loading status
    setIsCalibrationCampaignHistoryLoading(true);

    const response = await apiCalls.getCalibrationCampaignsHistory(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getCalibrationHistory()] response: ", response);

    // Resolve loading status
    setIsCalibrationCampaignHistoryLoading(false);

    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else if (response === 204) {
        setCalibrationCampaignHistory(null);
    } else {
      // 200 OK
      if (Configs.devEnvironment) console.log("[dataContext.js][getCalibrationHistory()] response: ", response);
      if (response.length > 0) {
        setCalibrationCampaignHistory(response);
      } else {
        setCalibrationCampaignHistory(null);
      }
      return response;
    }

  } catch (error) {
    console.error("[calibrationHome.js][getCalibrationHistory()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsCalibrationCampaignHistoryLoading(false);
    setCalibrationCampaignHistory(null);
  }
}

// ------------------------------------------------
// Pulse
// ------------------------------------------------
async function getPulseReportingData () {
    try {
      // Set loading status
      setIsPulseReportingDataLoading(true);

      const response = await apiCalls.getPulseReportingData(companyID, employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getPulseReportingData()] response: ", response);

      // Resolve loading status
      setIsPulseReportingDataLoading(false);
    
      // Review response status
      if (response === 500) {
        setPulseReportingData(null);
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
        setPulseReportingData(null);
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
      } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
        setPulseReportingData(null);
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getPulseReportingData()] response: ", response);
        if (response?.length > 0) {
          setPulseReportingData(response);
        } else {
          setPulseReportingData(null);
        }
      }

    } catch (error) {
      console.error("[dataContext.js][getPulseReportingData()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPulseReportingDataLoading(false);
    }
  
}

const getPulseKeyIndicators = useCallback( async () => {
  if (!companyID || !employeeID || !token || isPulseKeyIndicatorsLoading || pulseKeyIndicators) return;

    try {
      // Set loading status
      setIsPulseKeyIndicatorsLoading(true);

      const response = await apiCalls.getPulseKeyIndicators(companyID, employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getPulseKeyIndicators()] response: ", response);

      // Resolve loading status
      setIsPulseKeyIndicatorsLoading(false);

      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {  
        if (Configs.devEnvironment) console.log("[dataContext.js][getPulseKeyIndicators()] response: ", response);
        if (response.length > 0) {
          setPulseKeyIndicators(response);
        } else {
          setPulseKeyIndicators([]);
        }
      }
    } catch (error) {
      console.error("[dataContext.js][getPulseKeyIndicators()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPulseKeyIndicatorsLoading(false);
    }
}, [companyID, employeeID, token, correlationId, isPulseKeyIndicatorsLoading, pulseKeyIndicators]);

const getPulseHomeGraphFrontendData = useCallback(async () =>  {

    if (!companyID || !employeeID || !token || isPulseHomeGraphFrontendDataLoading || pulseHomeGraphFrontendData) return;

    try {
      // Set loading status
      setIsPulseHomeGraphFrontendDataLoading(true);

      const response = await apiCalls.getPulseHomeGraphFrontendData(companyID, employeeID, token, correlationId);
      if (Configs.devEnvironment) console.log("[dataContext.js][getPulseHomeGraphFrontendData()] response: ", response);

      // Resolve loading status
      setIsPulseHomeGraphFrontendDataLoading(false);

      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getPulseHomeGraphFrontendData()] response: ", response);
        if (response.length > 0) {
          setPulseHomeGraphFrontendData(response);
        } else {
          setPulseHomeGraphFrontendData([]);
        }
      }
    } catch (error) {
      console.error("[dataContext.js][getPulseHomeGraphFrontendData()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPulseHomeGraphFrontendDataLoading(false);
    }
},  [companyID, employeeID, token, correlationId, isPulseHomeGraphFrontendDataLoading, pulseHomeGraphFrontendData]);

const getAllPulseQuestions = useCallback(async () => {

  if (!companyID || !employeeID || !token || isAllPulseQuestionsLoading || allPulseQuestions) return;

  try {
    setIsAllPulseQuestionsLoading(true);

    const response = await apiCalls.getAllPulseQuestions(companyID, employeeID, token, correlationId);

    if (Configs.devEnvironment) console.log("[dataContext.js][getAllPulseQuestions()] response: ", response);

    setIsAllPulseQuestionsLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getAllPulseQuestions()] response: ", response);
      if (response.length > 0) {
        setAllPulseQuestions(response);
        sessionStorage.setItem('allPulseQuestions', CryptoJS.AES.encrypt(JSON.stringify(response), Configs.privateKey).toString());
      }
    }

  } catch (error) {
    console.error("[dataContext.js][getAllPulseQuestions()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setAllPulseQuestions(null);
    setIsAllPulseQuestionsLoading(false);
  }
}, [companyID, employeeID, token, correlationId, isAllPulseQuestionsLoading, allPulseQuestions]);

// ------------------------------------------------
// Onboarding
// ------------------------------------------------
async function getOnboardingReportingData () {
  try {
    // Set loading status
    setIsOnboardingReportingDataLoading(true);

    const response = await apiCalls.getOnboardingReportingData(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getOnboardingReportingData()] response: ", response);

    // Resolve loading status
    setIsOnboardingReportingDataLoading(false);
  
    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getOnboardingReportingData()] response: ", response);

      if (response.length > 0) setOnboardingReportingData(response);
    }

  } catch (error) {
    console.error("[dataContext.js][getOnboardingReportingData()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsOnboardingReportingDataLoading(false);
  }

}

async function getOnboardingEligibleEmployees () {
  try {
    // Set loading status 
    setIsOnboardingEligibleEmployeesLoading(true);

    const response = await apiCalls.getOnboardingEligibleEmployees(companyID, employeeID, token, correlationId);

    if (Configs.devEnvironment) console.log("[dataContext.js][getOnboardingEligibleEmployees()] response: ", response);

    setIsOnboardingEligibleEmployeesLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getOnboardingEligibleEmployees()] response: ", response);
      if (response.length > 0) setOnboardingEligibleEmployees(response);
    }

  } catch (error) {
    console.error("[dataContext.js][getOnboardingEligibleEmployees()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsOnboardingEligibleEmployeesLoading(false);
  }

}

async function getOnboardingTemplates () {
  
  try {
      setIsOnboardingPlanTemplatesLoading(true);

      const response = await apiCalls.getOnboardingTemplates(companyID, employeeID, token, correlationId);

      if (Configs.devEnvironment) console.log("[dataContext.js][getOnboardingTemplates()] response: ", response);

      setIsOnboardingPlanTemplatesLoading(false);

      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getOnboardingTemplates()] response: ", response);
        if (response.length > 0) setOnboardingPlanTemplates(response);
      }

  } catch (error) {
    console.error("[dataContext.js][getOnboardingTemplates()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsOnboardingPlanTemplatesLoading(false);
    setOnboardingPlanTemplates(null);
  }
}


// ------------------------------------------------
// ENGAGEMENT - 1on1s - MEETINGS
// ------------------------------------------------
async function getSpace (companyIDAux = companyID, employeeIDAux = employeeID, managerIDAux = profile?.employeeInfo?.managerID) {
    // set loading state
    setIsMeetingsDataLoading(true);

    try {
      const response = await apiCalls.getSpace(companyIDAux, employeeIDAux, token, managerIDAux, correlationId);
  
      if (Configs.devEnvironment) console.log("[dataContext.js][getSpace()] response: ", response);
  
      // resolve loading state
      setIsMeetingsDataLoading(false);
  
      // review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getSpace()] response: ", response); 
        if (response.length > 0) setMeetingsData(response);
      }

    } catch (error) {
      console.error("[dataContext.js][getSpace()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsMeetingsDataLoading(false);
    }
}

async function getOneOnOnesReport () {
    // Set loading state
    setIsOneOnOnesReportLoading(true);

    try {

      const response = await apiCalls.getOneOnOnesReport(companyID, employeeID, token, correlationId);
  
      if (Configs.devEnvironment) console.log("[dataContext.js][getOneOnOnesReport()] response: ", response);
  
      // Resolve loading state
      setIsOneOnOnesReportLoading(false);
  
      // Review response status
      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getOneOnOnesReport()] response: ", response); 
        if (response.length > 0) setOneOnOnesReport(response);
      }
    } catch (error) {
      console.error("[dataContext.js][getOneOnOnesReport()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsOneOnOnesReportLoading(false);
    }

  }

// ------------------------------------------------
// Recognition
// ------------------------------------------------
const getRecognitionDataForCompany = useCallback(async () => {
  if (!companyID || !employeeID || !token || isCompanyRecognitionDataLoading || companyRecognitionData) return;

  try {
    // Set loading status
    setIsCompanyRecognitionDataLoading(true);

    const response = await apiCalls.getRecognitionDataForCompany(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getRecognitionDataForCompany()] response: ", response);

    // Resolve loading status
    setIsCompanyRecognitionDataLoading(false);

    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getRecognitionDataForCompany()] response: ", response);

      if (response.length > 0) {
        setCompanyRecognitionData(response);
      } else {
        setCompanyRecognitionData([]);
      }
    }

  } catch (error) {
    console.error("[dataContext.js][getRecognitionDataForCompany()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsCompanyRecognitionDataLoading(false);
  }
}, [companyID, employeeID, token, correlationId, isCompanyRecognitionDataLoading, companyRecognitionData]);

const getRecognitionLeaderboardsForCompany = useCallback (async () => {
  if (!companyID || !employeeID || !token || isCompanyRecognitionLeaderboardDataLoading || companyRecognitionLeaderboardData) return;

  try {
    setIsCompanyRecognitionLeaderboardDataLoading(true);

    const response = await apiCalls.getRecognitionLeaderboardsForCompany(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getRecognitionLeaderboardsForCompany()] response: ", response);

    setIsCompanyRecognitionLeaderboardDataLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getRecognitionLeaderboardsForCompany()] response: ", response);

      if (response.length > 0) setCompanyRecognitionLeaderboardData(response);
    }

  } catch (error) {
    console.error("[dataContext.js][getRecognitionLeaderboardsForCompany()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsCompanyRecognitionLeaderboardDataLoading(false);
  }
}, [companyID, employeeID, token, correlationId, isCompanyRecognitionLeaderboardDataLoading, companyRecognitionLeaderboardData]);

async function getRewardsDataForCompany () {
  try {
    setIsCompanyRewardsDataLoading(true);

    const response = await apiCalls.getRewardsDataForCompany(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getRewardsDataForCompany()] response: ", response);

    setIsCompanyRewardsDataLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getRewardsDataForCompany()] response: ", response);

      if (response.length > 0) setCompanyRewardsData(response);

    }

  } catch (error) {
    console.error("[dataContext.js][getRewardsDataForCompany()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setCompanyRewardsData(null);
    setIsCompanyRewardsDataLoading(false);
  }
}


// ------------------------------------------------
// Career
// ------------------------------------------------
async function getJobLevellingGuidelinesData () {
  try {
    // Set loading status
    setIsJobLevellingGuidelinesDataLoading(true);

    const response = await apiCalls.getJobLevellingGuidelinesData(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getJobLevellingGuidelinesData()] response: ", response);

    // Resolve loading status
    setIsJobLevellingGuidelinesDataLoading(false);
  
    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getJobLevellingGuidelinesData()] response: ", response);

      if (response.length > 0) setJobLevellingGuidelinesData(response);
    }

  
  } catch (error) {
    console.error("[dataContext.js][getJobLevellingGuidelinesData()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsJobLevellingGuidelinesDataLoading(false);
  }
}

async function postJobLevellingGuideline (guidelineBodyInput) {
  try {
    setIsJobLevellingGuidelineLoading(true);

    const response = await apiCalls.postJobLevellingGuideline(companyID, employeeID, token, guidelineBodyInput, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][postJobLevellingGuideline()] response: ", response);

    setIsJobLevellingGuidelineLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 422) {
        setToasterMessage({message: t('error.validation'), type: Configs.errorToaster});
    } else if (response === 409) {
        setToasterMessage({message: t('error.conflict'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][postJobLevellingGuideline()] response: ", response);

      if (response){
        setToasterMessage({message: t('career.job-levelling-guideline.post.successful'), type: Configs.successToaster});
        getJobLevellingGuidelinesData();
        return 200;
      } 
    }

  } catch (error) {
    console.error("[dataContext.js][postJobLevellingGuideline()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsJobLevellingGuidelineLoading(false);
  }

}

async function putJobLevellingGuideline (guidelineBodyInput) {
  try {
    setIsJobLevellingGuidelineLoading(true);

    const response = await apiCalls.putJobLevellingGuideline(companyID, employeeID, token, guidelineBodyInput, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][putJobLevellingGuideline()] response: ", response);

    setIsJobLevellingGuidelineLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 422) {
        setToasterMessage({message: t('error.validation'), type: Configs.errorToaster});
    } else if (response === 409) {
        setToasterMessage({message: t('error.conflict'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][putJobLevellingGuideline()] response: ", response);
    }

  } catch (error) {
    console.error("[dataContext.js][putJobLevellingGuideline()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsJobLevellingGuidelineLoading(false);
  }
}

async function postNewCareerPathForEmployee (careerPath) {
  try {
    setIsCareerPathLoading(true);

    const response = await apiCalls.postNewCareerPathForEmployee(companyID, employeeID, token, careerPath, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][postNewCareerPathForEmployee()] response: ", response);

    setIsCareerPathLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 422) {
        setToasterMessage({message: t('error.validation'), type: Configs.errorToaster});
    } else if (response === 409) {
        setToasterMessage({message: t('error.conflict'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][postNewCareerPathForEmployee()] response: ", response);
      if (response){
        setToasterMessage({message: t('career.home.career-path.post.successful'), type: Configs.successToaster});
        getMyCompanyOrg();
        return 200;
      }
    }

  } catch (error) {
    console.error("[dataContext.js][postNewCareerPathForEmployee()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsCareerPathLoading(false);
  }

}

async function postCareerConversation (careerConversation) {
  try {
    setIsCareerConversationLoading(true);

    const response = await apiCalls.postNewCareerConversation(companyID, employeeID, token, careerConversation, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][postCareerConversation()] response: ", response);

    setIsCareerConversationLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 422) {
        setToasterMessage({message: t('error.validation'), type: Configs.errorToaster});
    } else if (response === 409) {
        setToasterMessage({message: t('error.conflict'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][postCareerConversation()] response: ", response);
      if (response){
        setToasterMessage({message: t('career.home.career-conversation.post.successful'), type: Configs.successToaster});
        return 200;
      }
    }

  } catch (error) {
    console.error("[dataContext.js][postCareerConversation()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsCareerConversationLoading(false);
  }

}

// ------------------------------------------------
// BASICS
// ------------------------------------------------
// Punch
async function getPunchHRReport () {
  try {
    setIsPunchHRReportLoading(true);

    const response = await apiCalls.getPunchHRReport(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getPunchHRReport()] response: ", response);

    setIsPunchHRReportLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getPunchHRReport()] response: ", response);
      if (response.length > 0) setPunchHRReport(response);
    }

  } catch (error) {
    console.error("[dataContext.js][getPunchHRReport()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsPunchHRReportLoading(false);
  }
}

async function getPunchesHistorySelf () {
  try {
    setIsPunchesHistorySelfLoading(true);

    const response = await apiCalls.getPunchStatusForEmployee(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getPunchesHistorySelf()] response: ", response);

    setIsPunchesHistorySelfLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getPunchesHistorySelf()] response: ", response);
      if (response.length > 0) setPunchesHistorySelf(response);
    }

  } catch (error) {
    console.error("[dataContext.js][getPunchesHistorySelf()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsPunchesHistorySelfLoading(false);
  }

}

async function getPunchEmployeeReport () {
  try {
    setIsPunchEmployeeReportLoading(true);

    const response = await apiCalls.getPunchReportEmployee(companyID, employeeID, token, correlationId);

    setIsPunchEmployeeReportLoading(false);

    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {  
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getPunchEmployeeReport()] response: ", response);
        setPunchEmployeeReport(response);
      }
  
    } catch (error) {
      console.error("[dataContext.js][getPunchEmployeeReport()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsPunchEmployeeReportLoading(false);
    }
  
  }

// Organigram
const getOrganigramData = useCallback(async ()  => {
  // Check if all required data is available
  if (!companyID || !employeeID || !token || isOrganigramDataLoading || organigramData) return;

  try {
    // Set loading status
    setIsOrganigramDataLoading(true);

    const response = await apiCalls.getOrganigramData(companyID, employeeID, token, correlationId);
    if (Configs.devEnvironment) console.log("[dataContext.js][getOrganigramData()] response: ", response);

    // Resolve loading status
    setIsOrganigramDataLoading(false);
  
    // Review response status
    if (response === 500) {
        setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    } else if (response === 404) {
        setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
    } else if (response === 403) {
        setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
    } else if (response === 401) {
        setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
        sessionStorage.clear();
        window.location.reload();
    } else if (response === 400) {
        setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
    } else {
      if (Configs.devEnvironment) console.log("[dataContext.js][getOrganigramData()] response: ", response);

      if (response.length > 0) setOrganigramData(response);
    }

  } catch (error) {
    console.error("[dataContext.js][getOrganigramData()] Error fetching data: ", error);
    setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
    setIsOrganigramDataLoading(false);
  }
}, [companyID, employeeID, token, correlationId, isOrganigramDataLoading, organigramData]);

// ------------------------------------------------
// SUPER ADMIN
// ------------------------------------------------
async function getCCOaaSReport () {
    try {
      setIsCCOaaSReportLoading(true);

      const response = await apiCalls.getCCOaaSReport(companyID, employeeID, token, correlationId);

      setIsCCOaaSReportLoading(false);

      if (response === 500) {
          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getCCOaaSReport()] response: ", response);
        if (response.length > 0) setCCOaaSReport(response);
      }

    } catch (error) {
      console.error("[dataContext.js][getCCOaaSReport()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsCCOaaSReportLoading(false);
    }
  }

async function getCCOaaSCompanyDetailsAIAnalysis (selectedCompanyID = 1) {
    try {
      setIsCCOaaSAICompanyDetailsAnalysisLoading(true);

      const response = await apiCalls.getCCOaaSCompanyDetailsAIAnalysis(companyID, employeeID, token, selectedCompanyID, correlationId);

      setIsCCOaaSAICompanyDetailsAnalysisLoading(false);

      if (response === 500) {

          setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      } else if (response === 404) {
          setToasterMessage({message: t('error.not-found'), type: Configs.errorToaster});
      } else if (response === 403) {
          setToasterMessage({message: t('error.unauthorized'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 401) {
          setToasterMessage({message: t('error.unauthenticated'), type: Configs.errorToaster});
          sessionStorage.clear();
          window.location.reload();
      } else if (response === 400) {
          setToasterMessage({message: t('error.bad-request'), type: Configs.errorToaster});
      } else {
        if (Configs.devEnvironment) console.log("[dataContext.js][getCCOaaSCompanyDetailsAIAnalysis()] response: ", response);
        if (response.length > 0) setCCOaaSCompanyDetailsAIAnalysis(JSON.parse(response));
      }
    } catch (error) {
      console.error("[dataContext.js][getCCOaaSCompanyDetailsAIAnalysis()] Error fetching data: ", error);
      setToasterMessage({message: t('error.server-unreachable'), type: Configs.errorToaster});
      setIsCCOaaSAICompanyDetailsAnalysisLoading(false);
    }
}

// LOGOUT
async function dataLogout () {
  // Set all variables to null
  setProfile(null);
  setCompanyPreferences(null);
  setCompanyBackgroundImageURL(null);
  setTeamDirects(null);
  setTeamOrg(null);
  setCompanyOrg(null);
  setSelectedProfile(null);
  setPendingToDos(null);
  setKeyKPIs(null);
  setPersonalPerformanceData(null);
  setLivePerformanceCampaign(null);
  setPerformanceReviewCampaigns(null);
  setPerformanceReviewHistory(null);
  setPerformanceFeedbackProviders(null);
  setPersonalPerformanceReviewDraft(null);
  setPerformanceReviewDraft(null);
  setPerformanceReviewManagerDraft(null);
  setCalibrationCampaign(null);
  setCalibrationCampaignHistory(null);
  setLiveCalibrationCampaign(null);
  setCompanyRecognitionData(null);
  setCompanyRecognitionLeaderboardData(null);
  setPulseReportingData(null);
  setPulseKeyIndicators(null);
  setPulseHomeGraphFrontendData(null);
  setAllPulseQuestions(null);
  setMeetingsData(null);
  setOneOnOnesReport(null);
  setOnboardingReportingData(null);
  setOnboardingEligibleEmployees(null);
  setOnboardingPlanTemplates(null);
  setJobLevellingGuidelinesData(null);
  setPunchHRReport(null);
  setPunchesHistorySelf(null);
  setOrganigramData(null);
  setCCOaaSReport(null);
  setCCOaaSCompanyDetailsAIAnalysis(null);
  setToasterMessage(null);
  setLogoPublicURL(null);
  setToasterMessage(null);
}

// ------------------------------------------------
// USE EFFECTS
// ------------------------------------------------
useEffect(() => {
  // Do not run if any of the required data is missing
  if (!(companyID && employeeID && token && securityLevel && correlationId)) return;

  const fetchData = async () => {
    try {
      // Company logo
      if (companyPreferences?.personalization?.logoSmall) getPublicLogoURL();
      if (companyPreferences?.personalization?.backgroundImageURL) getPublicBackgroundImageURL();

      // Team and company data
      if (teamDirects === null && securityLevel?.includes(Configs.securityLevelManager)) getMyDirectTeam();
      if (teamOrg === null && securityLevel?.includes(Configs.securityLevelManager)) getMyTeamOrg();
      if (companyOrg === null) getMyCompanyOrg();

      // ENGAGEMENT
      // Recognition
      if (companyPreferences?.activeModules["Recognition"] ) {
          if (companyRecognitionData === null) getRecognitionDataForCompany();
          if (companyRecognitionLeaderboardData === null) getRecognitionLeaderboardsForCompany();
      }

      if (companyPreferences?.activeModules["Rewards"] ) {
        if (companyRewardsData === null && !isCompanyRewardsDataLoading) getRewardsDataForCompany();
    }

      // Pulse
      if (companyPreferences?.activeModules["Pulse"]) {
          
          if ((securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))) {
            if (pulseKeyIndicators === null && !isPulseKeyIndicatorsLoading) getPulseKeyIndicators();
            if (pulseHomeGraphFrontendData === null && !isPulseHomeGraphFrontendDataLoading) getPulseHomeGraphFrontendData();
            if (allPulseQuestions === null && !isAllPulseQuestionsLoading) getAllPulseQuestions();
          }

          if ((securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))) {  
            if (pulseReportingData === null && !isPulseReportingDataLoading) getPulseReportingData();
          }
       }

       if (companyPreferences?.activeModules["1on1"]) {
        // if (selectedProfile && selectedProfile?.employeeID !== employeeID) {
        //   // Get selected profile space  
        //   getSpace(companyID, selectedProfile?.employeeID, selectedProfile?.managerID);
        // } else if (profile && !selectedProfile ) {
        //   // Get self space
        //   getSpace(companyID, employeeID, profile?.employeeInfo?.managerID);
        // }

        if (securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec) || securityLevel?.includes(Configs.securityLevelSuperAdmin)){
          if (oneOnOnesReport === null) getOneOnOnesReport();
        }
       }

       // PERFORMANCE
       // Calibration
        if (companyPreferences?.activeModules["Calibration"] ) {
          if (calibrationCampaign === null && (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))) getCalibrationData();
          if (calibrationCampaignHistory === null && (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec))) getCalibrationHistory();
        }

       // DEVELOP
       // Onboarding
       if (companyPreferences?.activeModules["Onboarding"] ) {
          if (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) {
            if (onboardingEligibleEmployees === null && (!isOnboardingEligibleEmployeesLoading)) getOnboardingEligibleEmployees();
            if (onboardingPlanTemplates === null && (!isOnboardingPlanTemplatesLoading)) getOnboardingTemplates();

          }
       }
       
       // Career
       if (companyPreferences?.activeModules["Career"] ) {
          if (jobLevellingGuidelinesData === null && (securityLevel?.includes(Configs.securityLevelIC))) getJobLevellingGuidelinesData();
       }

      // BASICS
      // Organigram
      if (companyPreferences?.activeModules["Organigram"]) {
          if (organigramData === null) getOrganigramData();
      }

      // Days Off
      if (companyPreferences?.activeModules["Time-off"]) {
        // Employee
        if (selfHolidays === null && !isSelfHolidaysLoading) getSelfHolidays();

        // Manager, HR, Exec
        if (securityLevel?.includes(Configs.securityLevelManager) || securityLevel?.includes(Configs.securityLevelHR) || securityLevel?.includes(Configs.securityLevelExec)) {
          getHolidaysTeamPendingRequests(); 
          getTeamHolidayCalendar();
        }
      }

      // Punch
      if (companyPreferences?.activeModules["PunchInOut"]) {
          if (punchHRReport === null && securityLevel?.includes(Configs.securityLevelHR)) getPunchHRReport();
          if (punchesHistorySelf === null && !isPunchesHistorySelfLoading) getPunchesHistorySelf();
          if (punchEmployeeReport === null && !isPunchEmployeeReportLoading) getPunchEmployeeReport();
      }

    } catch (error) {
      console.error("[dataContext.js][useEffect()] Error fetching data: ", error);
    }
    };

    if (companyID && employeeID && token && securityLevel) fetchData();


    if (Configs.devEnvironment) console.log("[dataContext.js][useEffect()] context: ", companyID, employeeID, securityLevel, profile, selectedProfile);
}, [employeeID, companyID, token, securityLevel, correlationId,
  getPublicLogoURL, getPublicBackgroundImageURL, // UI Personalization
  getOrganigramData, // ORGANIGRAM
  getRecognitionDataForCompany, getRecognitionLeaderboardsForCompany // RECOGNITION
]);

// ------------------------------------------------
useEffect(() => {
    // Do not run if any of the required data is missing
    if (!(companyID && employeeID && token && securityLevel && correlationId)) return;

  const fetchData = async () => {
    try {

      // User profile
      if (profile === null) getProfile();

      // Context switching
      if (selectedProfile && selectedProfile?.employeeID !== employeeID) {
          getProfile(selectedProfile?.employeeID, companyID, token, null);
      }
      
      // PERFORMANCE
       // Performance Review
       if (companyPreferences?.activeModules["Performance-Review"] ) {
        getLivePerformanceCampaigns();
        getPerformanceReviewHistory();
      }

      // ENGAGEMENT
      // 1:1s
       if (companyPreferences?.activeModules["1on1"]) {
        // if (selectedProfile && selectedProfile?.employeeID !== employeeID) {
        //   // Get selected profile space  
        //   getSpace(companyID, selectedProfile?.employeeID, selectedProfile?.managerID);
        // } else if (profile && !selectedProfile ) {
        //   // Get self space
        //   getSpace(companyID, employeeID, profile?.employeeInfo?.managerID);
        // }
       }

    } catch (error) {
      console.error("[dataContext.js][useEffect()] Error fetching data: ", error);
    }
    };

    if (companyID && employeeID && token && correlationId ) fetchData();

    if (Configs.devEnvironment) console.log("[dataContext.js][useEffect()] context: ", companyID, employeeID, securityLevel, profile, selectedProfile);
}, [employeeID, companyID, token, securityLevel, selectedProfile]);


// ------------------------------------------------
// SUPER ADMIN useEffect
useEffect(() => {
    // Do not run if any of the required data is missing
    if (!(companyID && employeeID && token && securityLevel && correlationId)) return;

    const fetchData = async () => {
      // SUPER ADMIN
      // if (CCOaaSReport === null) getCCOaaSReport();
      if (selectedCompany !== null) {
        if (CCOaaScompanyDetailsAIAnalysis === null && !isCCOaaSAICompanyDetailsAnalysisLoading) getCCOaaSCompanyDetailsAIAnalysis(selectedCompany);
      }
    };

    if (companyPreferences?.activeModules["Admin"] && securityLevel?.includes(Configs.securityLevelSuperAdmin)) {
      fetchData();
    }

  }, [employeeID, companyID, token, securityLevel, selectedCompany]);

// ------------------------------------------------
// Return
// ------------------------------------------------
  return (
    <DataContext.Provider value={{ 
      
            // Toaster
            toasterMessage, setToasterMessage, closeToaster,
            // Navbar
            navBarCollapsed, setNavBarCollapsed,
            
            // Feedback Popup
            showFeedbackPopup, setShowFeedbackPopup,

            // Company logo
            logoPublicURL, setLogoPublicURL,
      
            // Profile
            profile, setProfile, getProfile, isProfileLoading, errorLoadingProfile,

            // Company preferences,
            companyPreferences, setCompanyPreferences,

            // Company Background Image URL
            companyBackgroundImageURL, setCompanyBackgroundImageURL,

            // Team
            teamDirects, setTeamDirects, getMyDirectTeam, isDirectsLoading,
            teamOrg, setTeamOrg, getMyTeamOrg, isOrgLoading,
            companyOrg, setCompanyOrg, getMyCompanyOrg, isCompanyLoading,

            // Context switching
            selectedProfile, setSelectedProfile, isSelectedProfileLoading, setIsSelectedProfileLoading,

            // Home
            // Pending to-dos
            pendingToDos, setPendingToDos, getPendingToDos,
            isPendingToDosLoading, setIsPendingToDosLoading,

            // Key KPIs
            keyKPIs, setKeyKPIs, getKeyKPIs,
            isKeyKPIsLoading, setIsKeyKPIsLoading,

            // PERFORMANCE
            // Performance Review - Data Personal
            personalPerformanceData, setPersonalPerformanceData, getPersonalPerformanceData, 
            isPersonalPerformanceLoading, setIsPersonalPerformanceLoading,

            // Performance review - Live Campaigns
            getLivePerformanceCampaigns, isPerformanceLiveCampaignsLoading, setIsPerformanceLiveCampaignsLoading,
            livePerformanceCampaign, setLivePerformanceCampaign, 
            performanceReviewCampaigns, setPerformanceReviewCampaigns,
            getPerformanceReviewHistory, isPerformanceReviewHistoryLoading, setIsPerformanceReviewHistoryLoading,
            performanceReviewHistory, setPerformanceReviewHistory, 
            performanceFeedbackProviders, setPerformanceFeedbackProviders, getPerformanceFeedbackProviders,
            // Personal
            personalPerformanceReviewDraft, setPersonalPerformanceReviewDraft, getPersonalPerformanceReviewDraft,
            // Team 
            performanceReviewDraft, setPerformanceReviewDraft, getPerformanceReviewDraft,
            isPerformanceReviewDraftLoading, setIsPerformanceReviewDraftLoading,
            performanceReviewManagerDraft, setPerformanceReviewManagerDraft, getPerformanceReviewManagerDraft,
            isPerformanceReviewManagerDraftLoading, setIsPerformanceReviewManagerDraftLoading,

            // Calibration
            calibrationCampaign, setCalibrationCampaign, getCalibrationData, isCalibrationCampaignLoading, setIsCalibrationCampaignLoading,
            liveCalibrationCampaign, setLiveCalibrationCampaign,

            calibrationCampaignHistory, setCalibrationCampaignHistory, getCalibrationHistory, isCalibrationCampaignHistoryLoading, setIsCalibrationCampaignHistoryLoading,

            // ENGAGEMENT
            // Recognition
            companyRecognitionData, setCompanyRecognitionData, getRecognitionDataForCompany,
            isCompanyRecognitionDataLoading, setIsCompanyRecognitionDataLoading,
            companyRecognitionLeaderboardData, setCompanyRecognitionLeaderboardData, getRecognitionLeaderboardsForCompany,
            isCompanyRecognitionLeaderboardDataLoading, setIsCompanyRecognitionLeaderboardDataLoading,

            // Rewards
            companyRewardsData, setCompanyRewardsData, getRewardsDataForCompany,
            isCompanyRewardsDataLoading, setIsCompanyRewardsDataLoading,

            // Pulse
            pulseReportingData, setPulseReportingData, getPulseReportingData,isPulseReportingDataLoading, setIsPulseReportingDataLoading,
            pulseKeyIndicators, setPulseKeyIndicators, getPulseKeyIndicators, isPulseKeyIndicatorsLoading, setIsPulseKeyIndicatorsLoading,
            pulseHomeGraphFrontendData, setPulseHomeGraphFrontendData, getPulseHomeGraphFrontendData, isPulseHomeGraphFrontendDataLoading, setIsPulseHomeGraphFrontendDataLoading,
            allPulseQuestions, setAllPulseQuestions, getAllPulseQuestions, isAllPulseQuestionsLoading, setIsAllPulseQuestionsLoading,

            // Meetings - 1on1
            meetingsData, setMeetingsData, getSpace,isMeetingsDataLoading, setIsMeetingsDataLoading,
            oneOnOnesReport, setOneOnOnesReport, getOneOnOnesReport, isOneOnOnesReportLoading, setIsOneOnOnesReportLoading,

            // DEVELOPMENT
            // Onboarding
            onboardingReportingData, setOnboardingReportingData, getOnboardingReportingData,isOnboardingReportingDataLoading, setIsOnboardingReportingDataLoading,
            onboardingEligibleEmployees, setOnboardingEligibleEmployees, getOnboardingEligibleEmployees, isOnboardingEligibleEmployeesLoading, setIsOnboardingEligibleEmployeesLoading,
            onboardingPlanTemplates, setOnboardingPlanTemplates, getOnboardingTemplates, isOnboardingPlanTemplatesLoading, setIsOnboardingPlanTemplatesLoading,

            // Career
            jobLevellingGuidelinesData, setJobLevellingGuidelinesData, getJobLevellingGuidelinesData,
            isJobLevellingGuidelinesDataLoading, setIsJobLevellingGuidelinesDataLoading,

            postJobLevellingGuideline, putJobLevellingGuideline,
            isJobLevellingGuidelineLoading, setIsJobLevellingGuidelineLoading,

            selectedJobLevellingGuideline, setSelectedJobLevellingGuideline,

            postNewCareerPathForEmployee, isCareerPathLoading, setIsCareerPathLoading,
            postCareerConversation, isCareerConversationLoading, setIsCareerConversationLoading,

            // BASICS
            // Organigram
            organigramData, setOrganigramData, getOrganigramData, isOrganigramDataLoading, setIsOrganigramDataLoading,

            // Punch
            punchHRReport, setPunchHRReport, getPunchHRReport, isPunchHRReportLoading, setIsPunchHRReportLoading,
            punchesHistorySelf, setPunchesHistorySelf, getPunchesHistorySelf, isPunchesHistorySelfLoading, setIsPunchesHistorySelfLoading,
            punchEmployeeReport, setPunchEmployeeReport, getPunchEmployeeReport, isPunchEmployeeReportLoading, setIsPunchEmployeeReportLoading,

            // Time off
            holidaysTeamPendingRequests, setHolidaysTeamPendingRequests, getHolidaysTeamPendingRequests, isHolidaysTeamPendingRequestsLoading, setIsHolidaysTeamPendingRequestsLoading,
            teamHolidayCalendar, setTeamHolidayCalendar, getTeamHolidayCalendar, isTeamHolidayCalendarLoading, setIsTeamHolidayCalendarLoading,
            selfHolidays, setSelfHolidays, getSelfHolidays, isSelfHolidaysLoading, setIsSelfHolidaysLoading,

            // SUPER ADMIN
            selectedCompany, setSelectedCompany,
            CCOaaSReport, setCCOaaSReport, getCCOaaSReport, isCCOaaSReportLoading, setIsCCOaaSReportLoading,
            CCOaaScompanyDetailsAIAnalysis, setCCOaaSCompanyDetailsAIAnalysis, getCCOaaSCompanyDetailsAIAnalysis, isCCOaaSAICompanyDetailsAnalysisLoading, setIsCCOaaSAICompanyDetailsAnalysisLoading,

            // LOGOUT
            dataLogout,
        }}>
        {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);