import Configs from '../Configs';

// --------------------------------------------
// Handle API calls
// --------------------------------------------
const handleResponse = async (response, responseType = 'json') => {
    if (Configs.devEnvironment) console.log ("[apiCalls.js][handleResponse] response: ", response);

    if (response.status === 200 || response.status === 201 || response.status === 204) {
        try {
            if (responseType === 'blob') {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.download = response.headers['Content-Disposition']?.toString()?.split("filename=")[1]; // Extract filename from header
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                URL.revokeObjectURL(url);

                return { status: response.status, message: 'File downloaded successfully' };

            } else {
                const responseBody = await response?.json();
                return responseBody;
            }

        } catch (error) {
            if (response.status === 204) {
                return 204;
            }

            const auxResponse = {
                status: response.status,
                error: error
            }
            throw auxResponse;
        }
    } else {
        // throw new Error(errorData || 'Server error'); // Customize or parse error data as needed
        return response?.status;
    }
};

const getCsrfToken = () => {
    return document?.cookie?.split('; ')?.find(row => row?.startsWith('csrfToken'))?.split('=')[1];
  };

const fetchOptions = (method, data = null, token = null, responseType = 'json', correlationId = null) => {
    const csrfToken = getCsrfToken();

    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        headers.append('Accept', 'application/json');
        if (csrfToken) headers.append('CSRF-TOKEN', getCsrfToken());
    }

    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    if (correlationId) {
        headers.append('X-Correlation-ID', correlationId);  
    }

    const options = {
        method,
        headers,
        credentials: 'include', // if needed for cookies/cross-origin requests
    };

    options.responseType = responseType; // 'json', 'text', 'blob', etc.

    if (data) {
        options.body = JSON.stringify(data);
    }

    return options;
};

// --------------------------------------------
// API calls - export
// --------------------------------------------
// AUTHENTICATION
// AUTHENTICATE TOKEN
export const authenticateToken = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.authenticate, 
            fetchOptions('POST', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][authenticateToken()] We could not reach the server:', error);
        return 500;
    }
}

// LOGIN - BEARER
export const logIn = async (companyID, employeeID, token, loginBody, correlationId = null) => {
    try {
        const response = await fetch(Configs.loginAPI, 
            fetchOptions('POST', loginBody, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][logIn()] We could not reach the server:', error);
        return 500;
    }
}

// RESET PASSWORD
export const resetPassword = async (companyID, employeeID, token, resetPasswordBody, correlationId = null) => {
    try {
        const response = await fetch(Configs.resetPasswordAPI, 
            fetchOptions('POST', resetPasswordBody, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][resetPassword()] We could not reach the server:', error);
        return 500;
    }
}

// CHANGE PASSWORD
export const updatePassword = async (companyID, employeeID, token, updatePasswordBody, correlationId = null) => {
    try {
        const response = await fetch(Configs.updatePasswordAPI, 
            fetchOptions('POST', updatePasswordBody, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][updatePassword()] We could not reach the server:', error);
        return 500;
    }
}

// LOGOUT
export const logout = async (companyID, employeeID, token, correlationId = null) => {     
    try {
        const response = await fetch(Configs.logoutAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
             fetchOptions('POST', {}, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][logout()] We could not reach the server:', error);
        return 500;
    }
};

// --------------------------------------------
// HOME
// --------------------------------------------
// GET PENDING TO DOs
export const getPendingToDos = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPendingToDosAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPendingToDos()] We could not reach the server:', error);
        return 500;
    }
};

// GET KEY KPIs
export const getKeyKPIs = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetKeyKPIsForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPendingToDos()] We could not reach the server:', error);
        return 500;
    }
};

// --------------------------------------------
// ORGANIGRAM
// --------------------------------------------
// CREATE EMPLOYEE
export const postNewEmployee = async (companyID, employeeID, token, employeeObject, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostNewEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', employeeObject, token, 'json', correlationId));
        return await handleResponse(response);

    } catch (error) {
        console.error('[apiCalls.js][postNewEmployee()] We could not reach the server:', error);
        return 500;
    }
};

// EDIT EMPLOYEE
export const putEditEmployee = async (companyID, employeeID, token, employeeObject, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPutEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('PUT', employeeObject, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][putEditEmployee()] We could not reach the server:', error);
        return 500;
    }
};

// GET ORGANIGRAM DATA
export const getOrganigramData = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetOrganigramAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOrganigram()] We could not reach the server:', error);
        return 500;
    }
}

// GET ORGANIGRAM CSV
export const getOrganigramCSV = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetOrganigramCSVAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
             fetchOptions('GET', null, token, 'blob', correlationId));
        return await handleResponse(response, 'blob');
    } catch (error) {
        console.error('[apiCalls.js][getOrganigramCSV()] We could not reach the server:', error);
        return 500;
    }
}

// SUBMIT REORGANIZATION
export const postOrganigramReorganization = async (companyID, employeeID, token, reorganizationFileID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostOrganigramReorganizationAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&reorganizationFileID=" + reorganizationFileID,
         fetchOptions('POST', {
            companyID: companyID,
            employeeID: employeeID,
            reorganizationFileID: reorganizationFileID
         }, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postOrganigramReorganization()] We could not reach the server:', error);
        return 500;
    }
}


// --------------------------------------------
// DAYS OFF / TIME OFF
// --------------------------------------------
// GET TEAM PENDING REQUESTS
export const getHolidaysTeamPendingRequests = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffPendingRequestsForManagerAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getHolidaysTeamPendingRequests()] We could not reach the server:', error);
        return 500;
    }
}

export const getTeamHolidayCalendar = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetTeamHolidayCalendarAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getTeamHolidayCalendar()] We could not reach the server:', error);
        return 500;
    }
}

// POST NEW HOLIDAY REQUEST
export const postNewHolidayRequest = async (companyID, employeeID, token, holidayRequest, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostTimeOffAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', holidayRequest, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewHolidayRequest()] We could not reach the server:', error);
        return 500;
    }
}

// GET HOLIDAYS FOR EMPLOYEE
export const getHolidaysForEmployee = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getHolidaysForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

// AWARD DAYS TO EMPLOYEE
export const postTimeOffAwardDaysToEmployee = async (companyID, employeeID, token, data, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPutTimeOffAwardTimeOffToEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', data, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postTimeOffAwardDaysToEmployee()] We could not reach the server:', error);
        return 500;
    }

}

// --------------------------------------------
// HR INBOX
// --------------------------------------------
// GET HR INBOX MESSAGES FROM EMPLOYEE
export const getHRInboxMessagesForEmployee = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetInboxMessagesForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getHRInboxMessages()] We could not reach the server:', error);
        return 500;
    }
}

// GET HR INBOX MESSAGES FOR HR
export const getHRInboxMessagesForHR = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetInboxMessagesForCompanyAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getHRInboxMessagesForHR()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// PERFORMANCE
// --------------------------------------------
// GET PERSONAL PERFORMANCE DATA
export const getPersonalPerformanceData = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceCampaignsHistoryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPersonalPerformanceData()] We could not reach the server:', error);
        return 500;
    }
}

// GET LIVE PERFORMANCE CAMPAIGNS
export const getLivePerformanceCampaigns = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetLivePerformanceCampaignsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getLivePerformanceCampaigns()] We could not reach the server:', error);
        return 500;
    }
}

// SUBMIT FEEDBACK PROVIDERS
export const submitFeedbackProviders = async (companyID, employeeID, token, feedbackProviders, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostFeedbackProvidersAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', feedbackProviders, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][submitFeedbackProviders()] We could not reach the server:', error);
        return 500;
    }
}

// DELETE SINGLE FEEDBACK PROVIDER
export const deleteSingleFeedbackProvider = async (companyID, employeeID, token, campaignID, feedbackProviderEmail, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformDeleteFeedbackProviderAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID="+ campaignID+ "&feedbackProviderEmail=" + feedbackProviderEmail,
            fetchOptions('DELETE', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][deleteSingleFeedbackProvider()] We could not reach the server:', error);
        return 500;
    }
}

// EMPLOYEE DRAFT
export const getPerformanceReviewDraft = async (companyID, employeeID, token, campaignID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceDraftsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID=" + campaignID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewDraft()] We could not reach the server:', error);
        return 500;
    }
}

// MANAGER DRAFT
export const getPerformanceReviewManagerDraft = async (companyID, employeeID, token, campaignID, managerID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetManagerPerformanceDraftsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID=" + campaignID + "&managerID=" + managerID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewManagerDraft()] We could not reach the server:', error);
        return 500;
    }
}

// PERFORMANCE HISTORY
export const getPerformanceReviewHistory = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceCampaignsHistoryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewHistory()] We could not reach the server:', error);
        return 500;
    }
}

// GET FEEDBACK PROVIDERS OF CAMPAIGN
export const getPerformanceFeedbackProviders  = async (companyID, employee, token, campaignID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceFeedbackProvidersAPI + "?companyID=" + companyID + "&employeeID=" + employee + "&campaignID=" + campaignID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getFeedbackProviders()] We could not reach the server:', error);
        return 500;
    }
}

// GET HR LIVE CAMPAIGN REPORT
export const getPerformanceReviewStatusByCompany = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceReviewStatusByCompanyAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPerformanceReviewStatusByCompany()] We could not reach the server:', error);
        return 500;
    }
}

// GET SPECIFIC CAMPAIGN REVIEW
export const getSpecificPerformanceReview = async (companyID, employeeID, token, campaignID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetSpecificPerformanceReviewAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID=" + campaignID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getSpecificPerformanceReview()] We could not reach the server:', error);
        return 500;
    }
}

// GET SPECIFIC PERFORMANCE REVIEW BY STATUS AND EMPLOYEE
export const getPerformanceReviewsByStatusAndEmployee = async (companyID, employeeID, token, status, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPerformanceReviewByStatusAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&status=" + status, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getSpecificPerformanceReviewByStatusAndEmployee()] We could not reach the server:', error);
        return 500;
    }
}

// SUBMIT PERFORMANCE REVIEW WITH EMPLOYEE
export const sharePerformanceReview = async (companyID, employeeID, token, reviewData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformSubmitManagerFeedbackAPI, 
            fetchOptions('POST', reviewData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][sharePerformanceReview()] We could not reach the server:', error);
        return 500;
    }
}


// --------------------------------------------
// CALIBRATION
// --------------------------------------------
export const getCalibrationData = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetCalibrationCampaignsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCalibrationData()] We could not reach the server:', error);
        return 500;
    }
}

export const getCalibrationCampaignsHistory = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetCalibrationCampaignsHistoryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCalibrationCampaignsHistory()] We could not reach the server:', error);
        return 500;
    }
}   


// --------------------------------------------
// RECOGNITION
// --------------------------------------------
export const getRecognitionDataForCompany = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetRecognitionDataForCompanyAPI + "?companyID=" + companyID +"&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));    
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getRecognitionDataForCompany()] We could not reach the server:', error);
        return 500;
    }
}

export const getRecognitionLeaderboardsForCompany = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetRecognitionLeaderboardsForCompanyAPI + "?employeeID=" + employeeID + "&companyID=" + companyID,
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getRecognitionLeaderboardsForCompany()] We could not reach the server:', error);
        return 500;
    }
}

export const getPublicSingleRecognition = async (recognitionID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPublicSingleRecognitionAPI + "?recognitionID=" + recognitionID, 
            fetchOptions('GET', null, process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPublicSingleRecognition()] We could not reach the server:', error);
        return 500;
    }
}

export const postPlus1Recognition = async (companyID, employeeID, token, recognitionID, body, correlationId = null ) => {
    try {
        const response = await fetch(Configs.platformPostPlus1RecognitionAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&recognitionID=" + recognitionID, 
            fetchOptions('POST', body, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postPlus1Recognition()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// REWARDS
// --------------------------------------------
export const getRewardsDataForCompany = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetRewardsDataForCompanyAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getRewardsDataForCompany()] We could not reach the server:', error);
        return 500;
    }
}

export const postCashOutRewardForEmployee = async (companyID, employeeID, token, rewardData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostCashRewardOutForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', rewardData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postCashOutRewardForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// PULSE
// --------------------------------------------
// GET PULSE REPORTING DATA (i.e.: aggregated by manager data) - so we can filter by team - and also by category, subcategory of questions
export const getPulseReportingData = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.pulseReportingDataAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        if (Configs.devEnvironment) console.log("[apiCalls.js][getPulseReportingData()] response: ", response);
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseReportingData()] We could not reach the server:', error);
        return 500;
    }
}

export const getPulseHomeGraphFrontendData = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPulseHomeGraphFrontendDataAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseHomeGraphFrontendData()] We could not reach the server:', error);
        return 500;
    }
}

export const getPulseKeyIndicators = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPulseKeyIndicatorsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseKeyIndicators()] We could not reach the server:', error);
        return 500;
    }
}

// GET DAILY QUESTION
export const getPulseDailyQuestion = async (companyID, employeeID, token, questionDate, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPulseGetDailyQuestionAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&questionDate=" + questionDate
        , fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getDailyQuestion()] We could not reach the server:', error);
        return 500;
    }
}

// GET ALL PULSE QUESTIONS
export const getAllPulseQuestions = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.pulseGetAllQuestionsAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getAllPulseQuestions()] We could not reach the server:', error);
        return 500;
    }
}

// GET PULSE SURVEY
export const unauthenticatedGetPulseSurvey = async (companyID, employeeID, token, campaignID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformUnauthenticatedPulseGetSurveyAPI + "?companyID=" + companyID + "&campaignID=" + campaignID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
            return await handleResponse(response);
        } catch (error) {
            console.error('[apiCalls.js][unauthenticatedGetPulseSurvey()] We could not reach the server:', error);
            return 500;
        }
    }
    
export const unauthenticatedPostPulseSurveyAnswer = async (companyID, employeeID, token, campaignID, questionDate, answerData, correlationId = null) => {
    try {
        const response = await fetch(Configs.pulsePostSurveyAnswerAPI+ "?companyID=" + companyID + "&employeeID=" + employeeID + "&campaignID=" + campaignID + "&questionDate=" + questionDate, 
            fetchOptions('POST', answerData, token, 'json', correlationId));
            return await handleResponse(response);
        } catch (error) {
            console.error('[apiCalls.js][unauthenticatedPostPulseSurveyAnswer()] We could not reach the server:', error);
            return 500;
        }
    }
    
// GET QUESTION PROFILE
export const getQuestionProfile = async (companyID, employeeID, token, questionID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPulseQuestionProfileAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&questionID=" + questionID,
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getQuestionProfile()] We could not reach the server:', error);
        return 500;
    }
}

// GET DRIVER PROFILE
export const getDriverProfile = async (companyID, employeeID, token, driver, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPulseDriverProfileAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&driver=" + encodeURIComponent(driver),
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getDriverProfile()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// 1:1s - MEETINGS
// --------------------------------------------
export const getSpace = async (companyID, employeeID, token, managerID, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetMeetingsByEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&managerID=" + managerID, 
            fetchOptions('GET', null, token, 'json', correlationId));                
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getSpace()] We could not reach the server:', error);
        return 500;
    }
}

export const postNewMeeting = async (companyID, employeeID, token, meetingData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostMeetingAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', meetingData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewMeeting()] We could not reach the server:', error);
        return 500;
    }
}

export const getOneOnOnesReport = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetOneOnOnesReportAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOneOnOnesReport()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// ONBOARDING
// --------------------------------------------
export const getOnboardingReportingData = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingReportingDataAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPulseReportingData()] We could not reach the server:', error);
        return 500;
    }
}

export const getOnboardingEligibleEmployees = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingEligibleCandidatesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingEligibleEmployees()] We could not reach the server:', error);
        return 500;
    }
}

export const getOnboardingTemplates = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingPlanTemplatesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingTemplates()] We could not reach the server:', error);
        return 500;
    }
}

export const postOnboardingTemplate = async (companyID, employeeID, token, data, correlationId = null) => {

    try {
        const response = await fetch(Configs.platformPostOnboardingTemplateAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', data, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postOnboardingTemplate()] We could not reach the server:', error);
        return 500;
    }
}

export const deleteOnboardingTemplate = async (companyID, employeeID, token, data, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformDeleteOnboardingPlanTemplateAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('DELETE', data, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][deleteOnboardingTemplate()] We could not reach the server:', error);
        return 500;
    }
}

export const getOnboardingPlanForEmployee = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetOnboardingPlanForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingPlanForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

export const postOnboardingPlanSubsectionComplete = async (companyID, employeeID, token, data, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostOnboardingPlanSubsectionCompleteAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', data, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postOnboardingPlanSubsectionComplete()] We could not reach the server:', error);
        return 500;
    }
}

export const deleteOnboardingPlan = async (companyID, employeeID, token, data, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformDeleteOnboardingPlanAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('DELETE', data, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][deleteOnboardingPlan()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// CAREER
// --------------------------------------------
export const getJobLevellingGuidelinesData = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetCareerJobGuidelinesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getJobLevellingGuidelinesData()] We could not reach the server:', error);
        return 500;
    }
}

export const postJobLevellingGuideline = async (companyID, employeeID, token, jobGuidelineData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostCareerJobGuidelineAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', jobGuidelineData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postJobLevelleingGuideline()] We could not reach the server:', error);
        return 500;
    }
}

export const putJobLevellingGuideline = async (companyID, employeeID, token, jobGuidelineData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPutCareerJobGuidelineAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('PUT', jobGuidelineData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][putJobLevellingGuideline()] We could not reach the server:', error);
        return 500;
    }
}

export const postNewCareerPathForEmployee = async (companyID, employeeID, token, careerPathData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostCareerPathForEmployeeAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('POST', careerPathData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewCareerPathForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

export const postNewCareerConversation = async (companyID, employeeID, token, careerConversationData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostCareerConversationAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
         fetchOptions('POST', careerConversationData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postNewCareerConversation()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// PUNCH 
// --------------------------------------------
export const getPunchHRReport = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffHRPunchReportAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPunchHRReport()] We could not reach the server:', error);
        return 500;
    }
}

export const getPunchReportEmployee = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffPunchEmployeeReportAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
                fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPunchReportEmployee()] We could not reach the server:', error);
        return 500;
    }
}

export const getPunchStatusForEmployee = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffPunchStatusAPI + "?companyID=" + companyID + "&employeeID=" + employeeID, 
            fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPunchStatusForEmployee()] We could not reach the server:', error);
        return 500;
    }
}

export const postPunchWorkSheet = async (companyID, employeeID, token, body, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostTimeOffPunchSubmitMonthSheetAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
             fetchOptions('POST', body, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postPunchWorkSheet()] We could not reach the server:', error);
        return 500;
    }
}

export const getPunchEmployeeSchedules = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetTimeOffPunchEmployeeSchedulesAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
             fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPunchEmployeeSchedules()] We could not reach the server:', error);
        return 500;
    }
}

export const postPunchEmployeeSchedule = async (companyID, employeeID, token, body, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPostTimeOffPunchEmployeeScheduleAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
             fetchOptions('POST', body, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][postPunchEmployeeSchedule()] We could not reach the server:', error);
        return 500;
    }
}

export const deletePunchEmployeeSchedule = async (companyID, employeeID, token, body, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformDeleteTimeOffPunchEmployeeScheduleAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
             fetchOptions('DELETE', body, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][deletePunchEmployeeSchedule()] We could not reach the server:', error);
        return 500;
    }
}

export const putPunchEmployeeSchedule = async (companyID, employeeID, token, body, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPutTimeOffPunchEmployeeScheduleAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
             fetchOptions('PUT', body, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][putPunchEmployeeSchedule()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// FILES MANAGEMENT
// --------------------------------------------
export const getPublicURLForFile = async (companyID, employeeID, token, fileKey, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetPublicURLForFileAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&fileKey=" + fileKey,
             fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getPublicURLForFile()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// SUPER ADMIN
// --------------------------------------------
export const getCCOaaSReport = async (companyID, employeeID, token, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetCCOaaSReportAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
             fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCCOaaSReport()] We could not reach the server:', error);
        return 500;
    }
}

export const getCCOaaSCompanyDetailsAIAnalysis = async (companyID, employeeID, token, selectedCompanyID = 1, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformGetCCOaaSCompanyDetailsAIAnalysisAPI + "?companyID=" + companyID + "&employeeID=" + employeeID + "&selectedCompanyID=" + selectedCompanyID,
             fetchOptions('GET', null, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getCCOaaSCompanyDetailsAIAnalysis()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// AI
// --------------------------------------------
export const getOnboardingSurveysAISummary = async (companyID, employeeID, token, surveyData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformPOSTOnboardingSurveysAISummaryAPI + "?companyID=" + companyID + "&employeeID=" + employeeID,
             fetchOptions('POST', surveyData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][getOnboardingSurveysAISummary()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// Logging
// --------------------------------------------
export const logErrorToBackend = async (token, errorData, correlationId = null) => {
    try {
        const response = await fetch(Configs.platformLogErrorAPI,
            fetchOptions('POST', errorData, token, 'json', correlationId));
        return await handleResponse(response);
    } catch (error) {
        console.error('[apiCalls.js][logErrorToBackend()] We could not reach the server:', error);
        return 500;
    }
}

// --------------------------------------------
// PLG
// --------------------------------------------
export const getAccessTokenFromSlack = async (code, clientId, clientSecret) => {
    try {
        const url = Configs.slackExchangeTokenForCodeAPI
        
        const formData = new FormData();
        formData.append('code', code);
        formData.append('client_id', clientId);
        formData.append('client_secret', clientSecret);
        if (Configs.devEnvironment) console.log("[apiCalls.js][getAccessTokenFromSlack()] data: ", formData, clientId, clientSecret);
        const response = await fetch(url, {
            method: 'POST',
            body: formData  
        });

        if (response.ok) {
            const data = await response.json();
            if (Configs.devEnvironment) console.log("[apiCalls.js][getAccessTokenFromSlack()] data: ", data);
            return data;
        } else {
            console.error('[apiCalls.js][getAccessTokenFromSlack()] We could not reach the server:', response);
            return response.status;
        }

    } catch (error) {
        console.error('[apiCalls.js][getAccessTokenFromSlack()] We could not reach the server:', error);
        return 500;
    }
}

export const sendSlackAccessTokenToBackend = async (slackResponse) => {
    try {
        const response = await fetch(Configs.platformPostSlackAuthResponseAPI,
            fetchOptions('POST', slackResponse, process.env.REACT_APP_UNAUTHENTICATED_REQUESTS_API_TOKEN, 'json'));
       return await handleResponse(response);
   } catch (error) {
       console.error('[apiCalls.js][getCCOaaSCompanyDetailsAIAnalysis()] We could not reach the server:', error);
       return 500;
   }
}
